import * as React from "react"

function SvgAfiliatedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path id="afiliated-icon_svg__a" d="M0 0h32v32H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E84C89"
          d="M29.678.667c.886 0 1.607.725 1.607 1.615v28.175l-14.587-6.61-.698-.316-.698.316-14.587 6.61V2.282c0-.89.72-1.615 1.606-1.615h27.357"
        />
        <path
          fill="#B93568"
          d="M15.993 22.134l-.698-.317-.698.317-10.642 4.855V.667H2.322c-.886 0-1.607.725-1.607 1.615v28.175l14.587-6.61.698-.316.698.316 14.587 6.61-.885-1.796-14.407-6.527z"
        />
        <path
          fill="#231F20"
          d="M9.655 8.558c0-.918.747-1.664 1.664-1.664a1.665 1.665 0 010 3.329 1.667 1.667 0 01-1.664-1.665m1.664 3.52c1.941 0 3.52-1.58 3.52-3.52s-1.579-3.52-3.52-3.52-3.52 1.58-3.52 3.52 1.579 3.52 3.52 3.52M23.92 5.32a.98.98 0 00-1.358 0L8.08 19.8a.955.955 0 000 1.358.96.96 0 001.357 0L23.92 6.677a.962.962 0 000-1.357m-1.575 12.6c0 .918-.747 1.664-1.664 1.664a1.665 1.665 0 01-1.664-1.664c0-.918.746-1.664 1.664-1.664.917 0 1.664.746 1.664 1.664M20.68 14.4c-1.941 0-3.52 1.58-3.52 3.52s1.579 3.52 3.52 3.52 3.52-1.58 3.52-3.52-1.579-3.52-3.52-3.52"
        />
        <path
          fill="#231F20"
          d="M9.655 8.558c0-.918.747-1.664 1.664-1.664a1.665 1.665 0 010 3.329 1.667 1.667 0 01-1.664-1.665m1.664 3.52c1.941 0 3.52-1.58 3.52-3.52s-1.579-3.52-3.52-3.52-3.52 1.58-3.52 3.52 1.579 3.52 3.52 3.52M23.92 5.32a.98.98 0 00-1.358 0L8.08 19.8a.955.955 0 000 1.358.96.96 0 001.357 0L23.92 6.677a.962.962 0 000-1.357m-1.575 12.6c0 .918-.747 1.664-1.664 1.664a1.665 1.665 0 01-1.664-1.664c0-.918.746-1.664 1.664-1.664.917 0 1.664.746 1.664 1.664M20.68 14.4c-1.941 0-3.52 1.58-3.52 3.52s1.579 3.52 3.52 3.52 3.52-1.58 3.52-3.52-1.579-3.52-3.52-3.52"
        />
        <path
          fill="#FEFEFE"
          d="M10.295 9.198c0-.918.747-1.664 1.664-1.664a1.665 1.665 0 010 3.329 1.667 1.667 0 01-1.664-1.665m1.664 3.52c1.941 0 3.52-1.58 3.52-3.52s-1.579-3.52-3.52-3.52-3.52 1.58-3.52 3.52 1.579 3.52 3.52 3.52M24.56 5.96a.98.98 0 00-1.358 0L8.72 20.44a.955.955 0 000 1.358.96.96 0 001.357 0L24.56 7.317a.962.962 0 000-1.357m-1.575 12.6c0 .918-.747 1.664-1.664 1.664a1.665 1.665 0 01-1.664-1.664c0-.918.746-1.664 1.664-1.664.917 0 1.664.746 1.664 1.664m-1.664-3.52c-1.941 0-3.52 1.58-3.52 3.52s1.579 3.52 3.52 3.52 3.52-1.58 3.52-3.52-1.579-3.52-3.52-3.52"
        />
        <g>
          <mask id="afiliated-icon_svg__b" fill="#fff">
            <use xlinkHref="#afiliated-icon_svg__a" />
          </mask>
          <path
            fill="#231F20"
            d="M30.4 12.898v16.626l-13.742-6.195-.658-.297-.657.297L1.6 29.524V3.114c0-.836.678-1.514 1.514-1.514h25.772c.836 0 1.514.678 1.514 1.514v4.645a.801.801 0 001.6 0V3.114A3.114 3.114 0 0028.886 0H3.113A3.114 3.114 0 000 3.114V32l16-7.213L32 32V12.898a.801.801 0 00-1.6 0"
            mask="url(#afiliated-icon_svg__b)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgAfiliatedIcon
