import * as React from "react"

function SvgSearchIcon(props) {
  return (
    <svg
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}
    >
      <style>
        {
          ".prefix__shp0{fill:none;stroke:#808191;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5}"
        }
      </style>
      <path
        id="prefix__Layer"
        className="prefix__shp0"
        d="M15 16l4 4M1 9c0-4.42 3.58-8 8-8s8 3.58 8 8-3.58 8-8 8-8-3.58-8-8z"
      />
    </svg>
  )
}

export default SvgSearchIcon
