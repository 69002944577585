import * as React from "react"

function SvgNotResult(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="110"
      viewBox="0 0 100 110"
      {...props}
    >
      <defs>
        <path
          id="it1zmqcnqa"
          d="M1.498 9.089a16.216 16.216 0 00-1.002 5.628c0 9.357 7.967 16.94 17.8 16.94 6.53 0 12.245-3.35 15.34-8.345a18.381 18.381 0 01-9.032 2.343c-9.832 0-17.799-7.584-17.799-16.942A16.22 16.22 0 019.257.117C5.673 2.13 2.897 5.3 1.496 9.089z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EDEDEF"
          d="M20.016 94.816h-7.759c-5.712 0-10.343-3.734-10.343-8.34V9.75c0-4.605 4.631-8.339 10.343-8.339h7.759C14.302 1.41 9.67 5.144 9.67 9.75v76.727c0 4.605 4.63 8.339 10.345 8.339m54.153-64.252L51.62 32.058c-3.153 0-5.364-5.276-5.364-8.43V2.174a6.213 6.213 0 011.857 1.284L72.145 27.49c.54.538 1.714 2.39 2.023 3.074"
        ></path>
        <path
          fill="#020202"
          d="M2.015 30.18c.83 0 1.503-.669 1.503-1.494V11.553c0-4.707 3.784-8.537 8.431-8.537h13.18c.832 0 1.504-.67 1.504-1.494 0-.826-.672-1.495-1.503-1.495H11.95C5.642.027.511 5.197.511 11.553v17.133c0 .825.673 1.494 1.503 1.494m72.154 21.93c-.83 0-1.503.668-1.503 1.494v31.374c0 3.55-2.85 6.438-6.353 6.438H9.869c-3.501 0-6.35-2.888-6.35-6.438V35.793a1.5 1.5 0 00-1.504-1.494c-.832 0-1.503.669-1.503 1.494v49.185c0 5.197 4.199 9.429 9.357 9.429h56.443c5.16 0 9.359-4.232 9.359-9.43V53.605c0-.826-.673-1.495-1.503-1.495M47.506 25.134V3.904c.636.346 1.237.762 1.742 1.295l20.936 22.067c.903.951 1.547 2.08 1.964 3.298h-19.18c-3.01 0-5.462-2.437-5.462-5.43m24.866.081L51.435 3.15A10.025 10.025 0 0044.19.027H31.67c-.83 0-1.502.669-1.502 1.494s.672 1.494 1.502 1.494h12.52c.105 0 .207.028.31.033v22.086c0 4.64 3.8 8.418 8.469 8.418h19.697v8.251c0 .825.67 1.494 1.501 1.494.832 0 1.503-.669 1.503-1.494v-8.298c0-3.104-1.17-6.048-3.298-8.29"
        ></path>
        <path
          fill="#EA4C89"
          d="M79.137 82.253l18.134 16.383c2.472 2.426 2.562 6.292.201 8.62-2.357 2.325-6.262 2.226-8.708-.215L70.685 90.618l8.452-8.365z"
        ></path>
        <path
          fill="#EA4C89"
          d="M59.915 97.116c14.424 0 26.118-11.67 26.118-26.065 0-14.394-11.694-26.062-26.118-26.062-14.423 0-26.116 11.668-26.116 26.062 0 14.396 11.693 26.065 26.116 26.065"
        ></path>
        <path
          fill="#020202"
          d="M95.31 105.186a4.325 4.325 0 01-3.022 1.353c-1.1.05-2.253-.378-3.097-1.166L75.231 92.33a27.463 27.463 0 006.178-6.038l13.713 12.812a4.273 4.273 0 011.361 3.002 4.264 4.264 0 01-1.173 3.08m2.15-8.556L83.305 83.404a26.864 26.864 0 003.469-13.209c0-14.981-12.261-27.168-27.33-27.168-7.23 0-14.045 2.778-19.192 7.825a1.688 1.688 0 00-.017 2.4c.663.67 1.746.676 2.416.018 4.504-4.415 10.467-6.847 16.792-6.847 13.187 0 23.915 10.664 23.915 23.772 0 13.107-10.728 23.772-23.915 23.772S35.53 83.302 35.53 70.195c0-2.971.543-5.868 1.618-8.615a1.694 1.694 0 00-.974-2.198 1.71 1.71 0 00-2.21.968 26.913 26.913 0 00-1.85 9.845c0 14.98 12.261 27.168 27.33 27.168 4.621 0 8.972-1.156 12.79-3.177l14.62 13.66a7.701 7.701 0 005.296 2.091l.246-.003a7.709 7.709 0 005.403-2.423 7.62 7.62 0 002.1-5.51 7.625 7.625 0 00-2.438-5.371"
        ></path>
        <path
          fill="#FFF"
          d="M75.944 70.195c0-9.06-7.388-16.403-16.5-16.403-9.114 0-16.501 7.344-16.501 16.403 0 9.058 7.387 16.402 16.5 16.402s16.501-7.344 16.501-16.402"
        ></path>
        <g transform="translate(42 53.514)">
          <mask id="3qq35ml5gb" fill="#fff">
            <use xlinkHref="#it1zmqcnqa"></use>
          </mask>
        </g>
        <g>
          <path
            fill="#020202"
            d="M59.261 54.903c-8.594 0-15.584 6.929-15.584 15.448s6.99 15.447 15.584 15.447 15.584-6.928 15.584-15.447c0-8.52-6.99-15.448-15.584-15.448m0 35.014c-10.884 0-19.74-8.777-19.74-19.566s8.857-19.567 19.741-19.567 19.74 8.778 19.74 19.567-8.856 19.566-19.74 19.566"
          ></path>
          <path
            fill="#252525"
            d="M74.001 90.85l6.242-5.336 1.717-3.403 6.866 6.806-8.583 8.547z"
            opacity="0.383"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default SvgNotResult
