import * as React from "react"

function SvgMailFinish(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={42}
      viewBox="0 0 42 42"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={21} cy={21} r={21} fill="#FF78AC" />
        <path
          fill="#FFF"
          d="M27.742 28H14.258c-1.244-.001-2.252-.83-2.258-1.857v-9.286c0-.494.238-.967.662-1.315.423-.349.998-.544 1.596-.542h13.484c1.244.001 2.252.83 2.258 1.857v9.286c0 .494-.238.967-.662 1.315-.423.349-.998.544-1.596.542zM28 26v-8l-5.77 4.774c-.327.265-.773.413-1.238.413-.464 0-.91-.148-1.238-.413L14 18.011V26h14zm-2-9H16l5 4 5-4z"
        />
      </g>
    </svg>
  )
}

export default SvgMailFinish
