import * as React from "react"

function SvgEyeEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={23}
      viewBox="0 0 22 23"
      {...props}
    >
      <defs>
        <path
          id="EyeEdit_svg__a"
          d="M11 8.708A2.29 2.29 0 008.708 11 2.29 2.29 0 0011 13.292 2.29 2.29 0 0013.292 11 2.29 2.29 0 0011 8.708m0 6.111a3.82 3.82 0 010-7.639A3.822 3.822 0 0114.82 11 3.822 3.822 0 0111 14.82m0-9.55c-3.82 0-7.081 2.377-8.403 5.73C3.92 14.353 7.181 16.73 11 16.73c3.82 0 7.081-2.377 8.403-5.73C18.08 7.647 14.819 5.27 11 5.27z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="EyeEdit_svg__b" fill="#fff">
          <use xlinkHref="#EyeEdit_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#EyeEdit_svg__a" />
        <g fill="#252525" mask="url(#EyeEdit_svg__b)">
          <path d="M0 0h22v22H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgEyeEdit
