import * as React from "react"

function SvgInbox(props) {
  return (
    <svg
      width={42}
      height={34}
      viewBox="0 0 42 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8 24.6h-7.5c-.7 0-1.3-.3-1.7-.9L12.4 19H2.2c-1.1 0-2-.9-2-2s.9-2 2-2h11.2c.7 0 1.3.3 1.7.9l3.2 4.7h5.4l3.2-4.7c.4-.6 1-.9 1.7-.9h11.2c1.1 0 2 .9 2 2s-.9 2-2 2H29.6l-3.2 4.7c-.4.6-1 .9-1.6.9zM36 34H6C2.8 34 .2 31.4.2 28.2V17c0-.3.1-.6.2-.9L6.9 3.2C7.9 1.2 9.8 0 12 0h17.9c2.2 0 4.2 1.2 5.1 3.2l6.5 12.9c.1.3.2.6.2.9v11.2c.1 3.2-2.5 5.8-5.7 5.8zM4.2 17.5v10.8c0 .9.8 1.7 1.8 1.7h30c1 0 1.8-.8 1.8-1.8V17.5L31.5 5c-.3-.6-.9-1-1.6-1H12.1c-.7 0-1.3.4-1.6 1L4.2 17.5z"
        fill="#EA4C89"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgInbox
