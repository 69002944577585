import * as React from "react"

function SvgAnonimattaInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <g fill="#84858F" fillRule="nonzero">
        <path d="M13.964 4.116c-.032-.744-.153-1.255-.325-1.698a3.416 3.416 0 00-.81-1.242A3.439 3.439 0 0011.59.369c-.446-.172-.955-.292-1.699-.325C9.141.008 8.904 0 7.003 0c-1.901 0-2.14.008-2.886.041-.744.033-1.256.153-1.699.325-.47.178-.892.452-1.242.81-.355.35-.632.774-.807 1.239-.172.446-.292.954-.325 1.698C.008 4.863 0 5.101 0 7.001c0 1.901.008 2.14.041 2.886.033.744.153 1.255.326 1.698.177.47.454.892.81 1.242.35.355.773.632 1.238.807.446.172.955.292 1.7.325.746.033.984.041 2.885.041 1.901 0 2.14-.008 2.886-.041.744-.033 1.255-.153 1.699-.325a3.581 3.581 0 002.048-2.049c.173-.446.293-.954.326-1.698C13.992 9.14 14 8.902 14 7c0-1.9-.003-2.138-.036-2.885zm-1.26 5.716c-.03.684-.146 1.053-.241 1.3a2.325 2.325 0 01-1.33 1.328c-.246.096-.618.21-1.3.24-.738.034-.96.042-2.827.042-1.869 0-2.093-.008-2.829-.041-.684-.03-1.053-.145-1.3-.24a2.161 2.161 0 01-.804-.523c-.232-.227-.41-.5-.522-.804-.096-.246-.21-.618-.24-1.3-.034-.738-.042-.96-.042-2.827 0-1.868.008-2.092.041-2.828.03-.684.145-1.053.241-1.3.112-.303.29-.579.525-.803.227-.233.5-.41.804-.523.247-.095.619-.21 1.3-.24.738-.033.96-.041 2.828-.041 1.871 0 2.093.008 2.829.04.684.03 1.053.146 1.299.241.304.113.58.29.804.523.233.227.41.5.523.804.095.246.21.618.24 1.299.033.738.041.96.041 2.828 0 1.868-.008 2.087-.04 2.825z" />
        <path d="M7 3C4.792 3 3 4.792 3 7s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm0 6.595a2.595 2.595 0 110-5.19 2.595 2.595 0 010 5.19zM12 3a1 1 0 11-2 0 1 1 0 012 0z" />
      </g>
    </svg>
  )
}

export default SvgAnonimattaInstagram
