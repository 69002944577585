import * as React from "react"

function SvgUserWait(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={42}
      viewBox="0 0 42 42"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={21} cy={21} r={21} fill="#6E71A0" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M27.429 17.25C27.429 13.798 24.55 11 21 11c-3.55 0-6.429 2.798-6.429 6.25 0 1.9.873 3.603 2.25 4.749C13.954 23.462 12 26.384 12 29.75c0 .69.576 1.25 1.286 1.25.71 0 1.285-.56 1.285-1.25 0-3.452 2.879-6.25 6.429-6.25 3.55 0 6.429 2.798 6.429 6.25 0 .69.575 1.25 1.285 1.25S30 30.44 30 29.75c0-3.366-1.955-6.288-4.82-7.751 1.376-1.146 2.249-2.849 2.249-4.749zM21 21c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"
        />
      </g>
    </svg>
  )
}

export default SvgUserWait
