import * as React from "react"

function SvgPaymentComplete(props) {
  return (
    <svg
      width="133"
      height="130"
      viewBox="0 0 133 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.3001 102.6C98.3001 102.6 121 79.9 121 51.8C121 23.7 98.2001 1 70.3001 1C42.3001 1 19.6001 23.7 19.6001 51.8C19.6001 79.9 42.3001 102.6 70.3001 102.6Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M124.15 84.5768C126.414 84.5768 128.25 82.7412 128.25 80.4768C128.25 78.2125 126.414 76.3768 124.15 76.3768C121.885 76.3768 120.05 78.2125 120.05 80.4768C120.05 82.7412 121.885 84.5768 124.15 84.5768Z"
        fill="#EFF2F8"
      />
      <path
        d="M130.15 68.5768C131.696 68.5768 132.95 67.3232 132.95 65.7768C132.95 64.2304 131.696 62.9768 130.15 62.9768C128.604 62.9768 127.35 64.2304 127.35 65.7768C127.35 67.3232 128.604 68.5768 130.15 68.5768Z"
        fill="#EFF2F8"
      />
      <path
        d="M22.3 18.4C23.8464 18.4 25.1 17.1464 25.1 15.6C25.1 14.0537 23.8464 12.8 22.3 12.8C20.7536 12.8 19.5 14.0537 19.5 15.6C19.5 17.1464 20.7536 18.4 22.3 18.4Z"
        fill="#EFF2F8"
      />
      <path
        d="M5.2 72.4C8.07188 72.4 10.4 70.0719 10.4 67.2C10.4 64.3281 8.07188 62 5.2 62C2.32812 62 0 64.3281 0 67.2C0 70.0719 2.32812 72.4 5.2 72.4Z"
        fill="#EFF2F8"
      />
      <path
        d="M108.112 52.9993L110.814 52.1848C111.01 52.1279 111.01 51.8438 110.814 51.787L108.112 50.9724C108.042 50.9535 107.992 50.903 107.973 50.8335L107.159 48.1374C107.102 47.9417 106.818 47.9417 106.761 48.1374L105.946 50.8335C105.927 50.903 105.877 50.9535 105.807 50.9724L103.111 51.787C102.915 51.8438 102.915 52.1279 103.111 52.1848L105.814 52.9993C105.883 53.0182 105.934 53.0687 105.953 53.1382L106.767 55.8406C106.824 56.0364 107.108 56.0364 107.165 55.8406L107.979 53.1382C107.992 53.0687 108.042 53.0182 108.112 52.9993Z"
        fill="#AAB2C5"
      />
      <path
        d="M92.112 23.9996L94.8144 23.1852C95.0101 23.1283 95.0101 22.8442 94.8144 22.7874L92.112 21.9729C92.0425 21.954 91.992 21.9035 91.973 21.834L91.1585 19.138C91.1017 18.9423 90.8176 18.9423 90.7607 19.138L89.9462 21.834C89.9273 21.9035 89.8767 21.954 89.8073 21.9729L87.1112 22.7874C86.9154 22.8442 86.9154 23.1283 87.1112 23.1852L89.8136 23.9996C89.8831 24.0186 89.9336 24.0691 89.9525 24.1385L90.767 26.8409C90.8239 27.0366 91.108 27.0366 91.1648 26.8409L91.9794 24.1385C91.992 24.0691 92.0425 24.0186 92.112 23.9996Z"
        fill="#AAB2C5"
      />
      <path
        d="M51.4685 27.3736L53.8331 26.6609C54.0044 26.6112 54.0044 26.3626 53.8331 26.3128L51.4685 25.6002C51.4077 25.5836 51.3635 25.5394 51.347 25.4786L50.6342 23.1197C50.5845 22.9484 50.3359 22.9484 50.2862 23.1197L49.5735 25.4786C49.5569 25.5394 49.5127 25.5836 49.4519 25.6002L47.0928 26.3128C46.9215 26.3626 46.9215 26.6112 47.0928 26.6609L49.4575 27.3736C49.5182 27.3901 49.5624 27.4343 49.579 27.4951L50.2917 29.8596C50.3414 30.0309 50.59 30.0309 50.6398 29.8596L51.3525 27.4951C51.3635 27.4343 51.4077 27.3901 51.4685 27.3736Z"
        fill="#AAB2C5"
      />
      <path
        d="M34.4685 70.8023L36.8331 69.9877C37.0044 69.9309 37.0044 69.6468 36.8331 69.5899L34.4685 68.7754C34.4077 68.7565 34.3635 68.706 34.347 68.6365L33.6342 65.9404C33.5845 65.7446 33.3359 65.7446 33.2862 65.9404L32.5735 68.6365C32.5569 68.706 32.5127 68.7565 32.4519 68.7754L30.0928 69.5899C29.9215 69.6468 29.9215 69.9309 30.0928 69.9877L32.4575 70.8023C32.5182 70.8212 32.5624 70.8717 32.579 70.9412L33.2917 73.6436C33.3414 73.8394 33.59 73.8394 33.6398 73.6436L34.3525 70.9412C34.3635 70.8717 34.4077 70.8212 34.4685 70.8023Z"
        fill="#AAB2C5"
      />
      <path
        d="M30.6826 40.0049L32.3716 39.4959C32.4939 39.4604 32.4939 39.2828 32.3716 39.2473L30.6826 38.7383C30.6392 38.7264 30.6076 38.6949 30.5958 38.6515L30.0867 36.9665C30.0512 36.8442 29.8736 36.8442 29.8381 36.9665L29.329 38.6515C29.3171 38.6949 29.2856 38.7264 29.2422 38.7383L27.5571 39.2473C27.4347 39.2828 27.4347 39.4604 27.5571 39.4959L29.2461 40.0049C29.2895 40.0168 29.3211 40.0483 29.3329 40.0917L29.842 41.7806C29.8775 41.903 30.0551 41.903 30.0906 41.7806L30.5997 40.0917C30.6076 40.0483 30.6392 40.0168 30.6826 40.0049Z"
        fill="#AAB2C5"
      />
      <path
        d="M48.6821 56.7111L50.3711 56.2021C50.4935 56.1666 50.4935 55.989 50.3711 55.9535L48.6821 55.4444C48.6387 55.4326 48.6071 55.401 48.5953 55.3576L48.0862 53.6727C48.0507 53.5504 47.8731 53.5504 47.8376 53.6727L47.3285 55.3576C47.3167 55.401 47.2851 55.4326 47.2417 55.4444L45.5566 55.9535C45.4343 55.989 45.4343 56.1666 45.5566 56.2021L47.2456 56.7111C47.289 56.7229 47.3206 56.7545 47.3324 56.7979L47.8415 58.4868C47.877 58.6091 48.0546 58.6091 48.0901 58.4868L48.5992 56.7979C48.6071 56.7545 48.6387 56.7229 48.6821 56.7111Z"
        fill="#AAB2C5"
      />
      <path
        d="M63.0039 9.11993L64.6929 8.61089C64.8152 8.57537 64.8152 8.3978 64.6929 8.36229L63.0039 7.85325C62.9605 7.84142 62.9289 7.80985 62.917 7.76644L62.408 6.08149C62.3725 5.95916 62.1949 5.95916 62.1594 6.08149L61.6503 7.76644C61.6384 7.80985 61.6069 7.84142 61.5635 7.85325L59.8784 8.36229C59.756 8.3978 59.756 8.57537 59.8784 8.61089L61.5674 9.11993C61.6108 9.13176 61.6424 9.16333 61.6542 9.20674L62.1633 10.8956C62.1988 11.018 62.3764 11.018 62.4119 10.8956L62.921 9.20674C62.9289 9.16333 62.9605 9.13176 63.0039 9.11993Z"
        fill="#AAB2C5"
      />
      <path
        d="M103.027 81.4706L104.716 80.9616C104.838 80.9261 104.838 80.7485 104.716 80.713L103.027 80.204C102.983 80.1921 102.952 80.1606 102.94 80.1171L102.431 78.4322C102.395 78.3099 102.218 78.3099 102.182 78.4322L101.673 80.1171C101.661 80.1606 101.63 80.1921 101.586 80.204L99.9013 80.713C99.779 80.7485 99.779 80.9261 99.9013 80.9616L101.59 81.4706C101.634 81.4825 101.665 81.514 101.677 81.5574L102.186 83.2463C102.222 83.3687 102.399 83.3687 102.435 83.2463L102.944 81.5574C102.952 81.514 102.983 81.4825 103.027 81.4706Z"
        fill="#AAB2C5"
      />
      <path
        d="M55.3655 59.5507C51.1127 62.6491 45.5234 67.1448 45.5234 71.0938C45.5234 73.4066 45.5234 76.699 45.5234 79.0301C45.5234 79.9718 46.8724 80.5412 47.6509 80.0113C49.7026 78.6151 53.2498 77.0066 58.3424 77.4729C66.9693 78.2626 56.8235 63.8034 56.8235 63.8034L55.3655 59.5507Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M86.2889 59.5507C90.5416 62.7098 96.1309 67.1448 96.1309 71.0938C96.1309 73.4066 96.1309 76.699 96.1309 79.0301C96.1309 79.9718 94.7819 80.5412 94.0034 80.0113C91.9517 78.6151 88.4046 77.0066 83.312 77.4729C74.685 78.2626 84.8308 63.8034 84.8308 63.8034L86.2889 59.5507Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M70.797 16.0514C62.7776 21.7622 53.1786 35.7962 54.9404 53.6576C56.6415 71.5191 58.1603 80.3283 61.441 82.4546C64.7824 84.581 68.0631 85.067 70.797 85.067C73.5309 85.067 76.8115 84.581 80.153 82.4546C83.4944 80.3283 84.9525 71.5191 86.6536 53.6576C88.3546 35.7962 78.7556 21.7622 70.797 16.0514Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M76.8722 87.5579H64.7216C63.7495 87.5579 63.0205 86.7681 63.0205 85.8568V82.8799H78.6341V85.796C78.5733 86.7681 77.7835 87.5579 76.8722 87.5579Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M71.6474 57.5786H69.9463V79.9965H71.6474V57.5786Z"
        fill="#D6DCE8"
      />
      <path
        d="M70.7968 46.3673C74.1185 46.3673 76.8114 43.6745 76.8114 40.3527C76.8114 37.0309 74.1185 34.3381 70.7968 34.3381C67.475 34.3381 64.7822 37.0309 64.7822 40.3527C64.7822 43.6745 67.475 46.3673 70.7968 46.3673Z"
        stroke="#9FA8BA"
        stroke-width="4"
        stroke-miterlimit="10"
      />
      <path
        d="M78.4145 101.515C78.4145 106.044 74.7796 128.749 70.25 128.749C65.7203 128.749 62.0854 106.044 62.0854 101.515C62.0854 96.9851 65.7203 93.3502 70.25 93.3502C74.7796 93.3502 78.4145 97.041 78.4145 101.515Z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M71.0086 116.276C70.7472 117.086 69.7478 117.088 69.4857 116.278C68.0994 111.994 66.7827 104.728 66.7827 102.13C66.7827 100.229 68.3485 98.6628 70.2499 98.6628C72.1512 98.6628 73.717 100.229 73.717 102.13C73.717 104.731 72.3969 111.973 71.0086 116.276Z"
        fill="white"
        stroke="#D6DCE8"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  )
}

export default SvgPaymentComplete
