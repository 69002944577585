import * as React from "react"

function SvgIconUnitedKingdom(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#E9EDF5"
          d="M5.419 0v5.419H0v-.58h4.839V0zM12 4.839v.58H6.581V0h.58v4.839h1.334zM7.161 7.161V12h-.58V6.581H12v.58H7.548zm-1.742-.58V12h-.58V7.161H0v-.58z"
        />
        <path
          fill="#E9EDF5"
          d="M4.839 7.548v.947L1.334 12h-.56a.752.752 0 01-.317-.07l4.382-4.382z"
        />
        <path
          fill="#E9EDF5"
          d="M4.839 7.161v.387L.457 11.93A.771.771 0 010 11.226v-.56l3.505-3.505H4.84zM12 10.666v.56c0 .11-.024.218-.07.317L7.548 7.161h.947L12 10.666z"
        />
        <path
          fill="#E9EDF5"
          d="M7.548 7.161l4.382 4.382a.771.771 0 01-.704.457h-.56L7.161 8.495V7.16h.387zM12 .774v.56L8.495 4.839H7.16v-.387L11.543.07c.278.124.457.4.457.704z"
        />
        <path
          fill="#E9EDF5"
          d="M11.543.07L7.161 4.452v-.968h.022L10.666 0h.56c.11 0 .218.024.317.07zM4.452 4.839h-.947L0 1.334v-.56C0 .664.024.556.07.457l4.382 4.382z"
        />
        <path
          fill="#E9EDF5"
          d="M4.839 3.505V4.84h-.387L.07.457A.771.771 0 01.774 0h.56l3.505 3.505z"
        />
        <path
          fill="#E21743"
          d="M12 5.419v1.162H6.581V12H5.419V6.581H0V5.419h5.419V0h1.162v5.419z"
        />
        <path
          fill="#2448A8"
          d="M3.505 7.161L0 10.666V7.161zm1.334 1.334V12H1.334zM10.666 12H7.161V8.495zM12 7.161v3.505L8.495 7.161zm0-5.827v3.505H8.495zM10.666 0L7.183 3.484h-.022V0zM3.505 4.839H0V1.334zM4.839 0v3.505L1.334 0z"
        />
        <path
          fill="#1F3D8F"
          d="M12 4.839V3.097c0 .6-.127 1.194-.373 1.742H12z"
        />
        <path
          fill="#DCE1EB"
          d="M.373 4.839H0v.58h.69a4.34 4.34 0 01-.317-.579zm10.937.581H12v-.581h-.373c-.09.201-.196.395-.317.58zM9.014 7.161H12v-.58h-1.811c-.36.253-.755.449-1.175.58z"
        />
        <path
          fill="#CDD2E1"
          d="M7.161 7.355h-.58V12h.58V8.495zm-2.322 0V12h.58V7.355z"
        />
        <path
          fill="#DCE1EB"
          d="M1.811 6.58H0v.581h2.986a4.222 4.222 0 01-1.175-.58z"
        />
        <path
          fill="#CDD2E1"
          d="M4.839 7.548L.457 11.93c.1.046.208.07.317.07h.56l3.505-3.505v-.947z"
        />
        <path
          fill="#CDD2E1"
          d="M4.839 7.355h-.58c-.29 0-.578-.03-.86-.087L0 10.666v.56c0 .304.179.58.457.704l4.382-4.382v-.193zm2.903 0l4.188 4.188c.046-.1.07-.208.07-.317v-.56L8.601 7.268c-.282.058-.57.087-.86.087z"
        />
        <path
          fill="#CDD2E1"
          d="M7.161 7.355v1.14L10.666 12h.56c.304 0 .58-.179.704-.457L7.742 7.355h-.58z"
        />
        <path
          fill="#B51135"
          d="M10.189 6.58H12V5.42h-.69a4.282 4.282 0 01-1.121 1.16zm-4.77.775H6.58V12H5.419zM.69 5.42H0v1.16h1.811A4.254 4.254 0 01.69 5.42z"
        />
        <path
          fill="#1F3D8F"
          d="M2.986 7.161H0v3.505l3.399-3.398a4.1 4.1 0 01-.413-.107zM1.334 12h3.505V8.495zm5.827 0h3.505L7.161 8.495zm1.44-4.732L12 10.666V7.161H9.014a4.1 4.1 0 01-.413.107zM0 3.097v1.742h.373A4.258 4.258 0 010 3.097z"
        />
        <path fill="#BEC3D2" d="M6.581 11.226h1v1h-1zm-1.742 0h1v1h-1z" />
        <path fill="#8B0B27" d="M5.419 11.226H6.58v1H5.419z" />
        <path
          fill="#172E6B"
          d="M2.108 11.226L1.334 12h3.505v-.774zm5.053 0V12h3.505l-.774-.774z"
        />
        <path
          fill="#BEC3D2"
          d="M1.435 11.226h-.66a.774.774 0 01-.75-.585L0 10.666v.56a.754.754 0 00.573.743.72.72 0 00.201.031h.56l.774-.774h-.673zm10.539-.586a.759.759 0 01-.263.41.857.857 0 01-.485.176H9.892l.774.774h.56a.754.754 0 00.743-.573.72.72 0 00.031-.201v-.56l-.026-.025z"
        />
        <path
          fill="#F7FAFF"
          d="M4.839 0h1v1h-1zm1.742 0h1v1h-1zm4.962.07a.758.758 0 00-.317-.07h-.56l-.774.774h1.334c.354 0 .662.242.748.585L12 1.334v-.56a.771.771 0 00-.457-.704zM1.334 0h-.56A.771.771 0 00.07.457.758.758 0 000 .774v.56l.026.025A.776.776 0 01.774.774h1.334L1.334 0z"
        />
        <path fill="#EA5172" d="M5.419 0H6.58v1H5.419z" />
        <path
          fill="#376DFF"
          d="M9.892.774L10.666 0H7.161v.774zm-5.053 0V0H1.334l.774.774z"
        />
      </g>
    </svg>
  )
}

export default SvgIconUnitedKingdom
