import * as React from "react"

function SvgBill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={96}
      height={96}
      viewBox="0 0 96 96"
      {...props}
    >
      <defs>
        <path id="bill_svg__a" d="M.059 0h62.813v80.668H.059z" />
        <path id="bill_svg__c" d="M.024.016H70V80.64H.024z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h96v96H0z" />
        <path
          fill="#54768A"
          d="M68.122 85.465l-49.077 8.612c-2.509.436-4.908-1.2-5.344-3.707L1.922 23.656c-.436-2.507 1.2-4.905 3.708-5.341l49.186-8.612c2.509-.436 4.908 1.199 5.344 3.706l11.67 66.714c.545 2.507-1.2 4.906-3.708 5.342"
        />
        <path
          fill="#54768A"
          d="M27.879 85.465l49.076 8.612c2.509.436 4.908-1.2 5.344-3.707l11.779-66.714c.436-2.507-1.2-4.905-3.708-5.341L41.184 9.703c-2.509-.436-4.908 1.199-5.344 3.706L24.06 80.123c-.436 2.507 1.31 4.906 3.818 5.342"
        />
        <path
          fill="#E0EFFF"
          d="M72.92 78.815H23.08a4.654 4.654 0 01-4.69-4.687V6.432c0-2.507 2.072-4.578 4.69-4.578h49.84c2.509 0 4.58 2.071 4.58 4.578v67.696c0 2.616-2.071 4.687-4.58 4.687"
        />
        <g transform="translate(16.48)">
          <mask id="bill_svg__b" fill="#fff">
            <use xlinkHref="#bill_svg__a" />
          </mask>
          <path
            fill="#000"
            d="M56.44 80.668H6.6c-3.67 0-6.541-2.873-6.541-6.54v-5.56a1.851 1.851 0 113.705 0v5.56A2.805 2.805 0 006.6 76.961h49.84c1.503 0 2.728-1.27 2.728-2.833V6.433c0-1.478-1.252-2.726-2.728-2.726H6.6c-1.563 0-2.836 1.222-2.836 2.726v54.613a1.852 1.852 0 11-3.705 0V6.433C.059 2.886 2.993 0 6.6 0h49.84a6.44 6.44 0 016.432 6.433v67.695c0 3.605-2.885 6.54-6.432 6.54"
            mask="url(#bill_svg__b)"
          />
        </g>
        <path
          fill="#000"
          d="M47.946 37.5c-5.2 0-9.27-2.968-9.27-6.757a1.852 1.852 0 113.705 0c0 1.44 2.382 3.05 5.565 3.05 3.246 0 5.673-1.61 5.673-3.05 0-1.362-2.48-2.945-5.673-2.945a1.852 1.852 0 01-1.853-1.853c0-1.023.827-1.853 1.853-1.853 5.26 0 9.378 2.921 9.378 6.65 0 3.79-4.118 6.759-9.378 6.759"
        />
        <path
          fill="#000"
          d="M47.946 41.643a1.852 1.852 0 01-1.853-1.853v-4.143a1.852 1.852 0 113.705 0v4.143a1.851 1.851 0 01-1.852 1.853m0-13.845c-5.2 0-9.27-2.968-9.27-6.758 0-3.667 4.156-6.65 9.27-6.65 5.26 0 9.378 2.921 9.378 6.65a1.852 1.852 0 11-3.705 0c0-1.361-2.48-2.944-5.673-2.944-3.134 0-5.565 1.583-5.565 2.944 0 1.44 2.382 3.052 5.565 3.052 1.025 0 1.852.83 1.852 1.853a1.851 1.851 0 01-1.852 1.853"
        />
        <path
          fill="#000"
          d="M47.946 18.096a1.852 1.852 0 01-1.853-1.853V12.1a1.852 1.852 0 113.705 0v4.143a1.851 1.851 0 01-1.852 1.853m0 52.325H30.715a1.85 1.85 0 01-1.852-1.853 1.85 1.85 0 011.852-1.853h17.23c1.026 0 1.853.83 1.853 1.853a1.851 1.851 0 01-1.852 1.853m17.339-8.938h-34.57a1.85 1.85 0 01-1.852-1.853 1.85 1.85 0 011.852-1.854h34.57a1.85 1.85 0 011.852 1.854 1.85 1.85 0 01-1.852 1.853m0-8.94h-34.57a1.85 1.85 0 01-1.852-1.853 1.85 1.85 0 011.852-1.853h34.57a1.85 1.85 0 011.852 1.853 1.85 1.85 0 01-1.852 1.853"
        />
        <g transform="translate(26 15.36)">
          <mask id="bill_svg__d" fill="#fff">
            <use xlinkHref="#bill_svg__c" />
          </mask>
          <path
            fill="#000"
            d="M58.593 64a1.854 1.854 0 01-1.829-2.175l9.487-53.85c.263-1.502-.726-2.939-2.202-3.196l-6.32-1.087a1.853 1.853 0 01-1.51-2.143C56.392.543 57.354-.115 58.36.038l6.324 1.089c3.49.61 5.831 3.967 5.219 7.486l-9.487 53.854c-.158.9-.94 1.532-1.823 1.532M51.76 80.64c-.368 0-.744-.032-1.12-.098L1.56 71.93a1.854 1.854 0 01-1.507-2.146c.177-1.01 1.154-1.662 2.146-1.504l49.076 8.613c1.545.267 2.942-.727 3.198-2.199l.984-5.565c.177-1.01 1.157-1.675 2.15-1.503a1.856 1.856 0 011.502 2.148l-.984 5.56c-.541 3.108-3.276 5.307-6.365 5.307"
            mask="url(#bill_svg__d)"
          />
        </g>
        <path
          fill="#000"
          d="M18.241 96c-3.088 0-5.824-2.199-6.365-5.312L.097 23.978c-.612-3.524 1.729-6.881 5.215-7.49l6.328-1.09a1.854 1.854 0 01.631 3.654L5.947 20.14c-1.473.255-2.46 1.692-2.198 3.198l11.78 66.709c.259 1.48 1.679 2.465 3.2 2.204l14.282-2.509c.969-.16 1.968.496 2.145 1.504a1.853 1.853 0 01-1.507 2.147l-14.285 2.508a6.48 6.48 0 01-1.123.098"
        />
      </g>
    </svg>
  )
}

export default SvgBill
