import * as React from "react"

function SvgUserAdminWhite(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M12.975 11.401c-.913 0-1.352.495-2.85.495s-1.934-.495-2.85-.495C4.915 11.401 3 13.263 3 15.557v.792c0 .82.684 1.484 1.527 1.484h11.196c.843 0 1.527-.665 1.527-1.484v-.792c0-2.294-1.915-4.156-4.275-4.156zm2.748 4.948H4.527v-.792c0-1.472 1.234-2.672 2.748-2.672.464 0 1.218.495 2.85.495 1.644 0 2.382-.495 2.85-.495 1.514 0 2.748 1.2 2.748 2.672v.792zm-5.598-5.443c2.529 0 4.58-1.994 4.58-4.453 0-2.458-2.051-4.453-4.58-4.453s-4.58 1.995-4.58 4.453c0 2.459 2.051 4.453 4.58 4.453zm0-7.422c1.683 0 3.054 1.333 3.054 2.97 0 1.635-1.371 2.968-3.054 2.968-1.683 0-3.054-1.333-3.054-2.969s1.371-2.969 3.054-2.969z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgUserAdminWhite
