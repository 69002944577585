import * as React from "react"

function SvgIconSpain(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#E21743"
          d="M11.813.774v3.097H.187V.774A.764.764 0 01.938 0h10.126c.414 0 .75.347.75.774z"
        />
        <path fill="#FFD45D" d="M.188 3.871h11.625v4.258H.188z" />
        <path
          fill="#E21743"
          d="M11.813 8.129v3.097a.763.763 0 01-.75.774H.937a.763.763 0 01-.75-.774V8.129h11.626z"
        />
        <path
          fill="#C7002B"
          d="M.188 3.097v.774h.069a4.369 4.369 0 01-.07-.774zm11.625.774v-.774c0 .26-.024.519-.07.774h.07z"
        />
        <path
          fill="#FFB844"
          d="M7.688 7.355H4.313C2.322 7.355.618 5.89.256 3.87h-.07v4.258h11.626V3.871h-.07c-.361 2.019-2.067 3.484-4.056 3.484z"
        />
        <path
          fill="#C7002B"
          d="M.188 11.226c0 .427.335.774.75.774h10.124c.415 0 .75-.347.75-.774V8.129H.188v3.097z"
        />
        <path
          fill="#B51135"
          d="M11.063 11.226H.937a.763.763 0 01-.75-.774v.774c0 .427.336.774.75.774h10.126c.414 0 .75-.347.75-.774v-.774a.763.763 0 01-.75.774z"
        />
        <path
          fill="#EA5172"
          d="M11.063 0H.937a.763.763 0 00-.75.774v.774c0-.427.336-.774.75-.774h10.126c.414 0 .75.347.75.774V.774a.763.763 0 00-.75-.774z"
        />
        <path
          fill="#E21743"
          stroke="#000"
          strokeWidth={0.3}
          d="M3.563 4.452a.36.36 0 01.267.11c.07.074.11.173.107.277a.35.35 0 01-.185.337.38.38 0 00-.19.337v.1H2.438v-.1a.38.38 0 00-.19-.337.35.35 0 01-.186-.337.384.384 0 01.108-.276.357.357 0 01.268-.111h1.124z"
        />
        <path
          fill="#EEF2FA"
          stroke="#000"
          strokeWidth={0.3}
          d="M3.938 5.613v.968H3v-.968h.563z"
        />
        <path
          fill="#E21743"
          stroke="#000"
          strokeWidth={0.3}
          d="M3 6.58v.968c-.518 0-.938-.133-.938-.774v-.193H3zm.938 0v.194c0 .64-.42.774-.938.774v-.967h.938z"
        />
        <path
          fill="#E21743"
          stroke="#000"
          strokeWidth={0.3}
          d="M3 5.613v.968h-.937v-.968h.375z"
        />
        <path
          fill="#C7002B"
          d="M3 7.13a3.249 3.249 0 01-.563-.244c-.061-.032-.126-.066-.187-.102-.064-.039-.126-.081-.188-.123v.113a.75.75 0 00.188.552v.002l.01.009c.02.019.042.036.064.052l.02.015a.57.57 0 00.094.051.88.88 0 00.187.058v.001c.062.013.125.022.188.027.06.004.123.007.187.007.316 0 .595-.05.765-.235A4.114 4.114 0 013 7.131z"
          style={{
            mixBlendMode: "multiply",
          }}
        />
        <path fill="#000" d="M4.313 5.419h1v2.129h-1zm-2.813 0h1v2.129h-1z" />
      </g>
    </svg>
  )
}

export default SvgIconSpain
