import * as React from "react"

function SvgCreditCard(props) {
  return (
    <svg
      width={16}
      height={12}
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.25 0H1.75C.785 0 0 .856 0 1.91v8.18C0 11.145.785 12 1.75 12h12.5c.965 0 1.75-.856 1.75-1.91V1.91C16 .855 15.215 0 14.25 0zM1.75 1.09h12.5c.414 0 .75.368.75.82V3H1V1.91c0-.452.336-.82.75-.82zm12.5 9.82H1.75c-.414 0-.75-.368-.75-.82v-6h14v6c0 .452-.336.82-.75.82z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgCreditCard
