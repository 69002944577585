import * as React from "react"

function SvgLandingPageYoutube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M9.765 15.301c1.867-1.002 3.728-1.99 5.61-2.993-1.867-1.01-3.713-2.004-5.566-3.006l-.044 6m10.779-7.615c.644 1.752.618 8.133-.157 9.82-.213.462-.551.779-.98 1.017-.607.323-4.128.476-7.578.476-3.176 0-6.291-.13-7.02-.375-.677-.23-1.135-.692-1.375-1.385-.582-1.694-.712-8.79.453-10.188a2.23 2.23 0 011.51-.822C6.781 6.076 9.451 6 12.084 6c3.28 0 6.502.117 7.113.345.648.238 1.107.678 1.347 1.342"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default SvgLandingPageYoutube
