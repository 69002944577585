import * as React from "react"

function SvgTwitterActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={16}
      viewBox="0 0 20 16"
      {...props}
    >
      <path
        d="M20 1.894a8.683 8.683 0 01-2.363.638c.85-.5 1.5-1.285 1.804-2.232a8.26 8.26 0 01-2.6.978A4.133 4.133 0 0013.846 0C11.576 0 9.75 1.814 9.75 4.038c0 .32.027.628.095.92A11.66 11.66 0 011.393.737 3.998 3.998 0 00.83 2.776a4.03 4.03 0 001.821 3.356 4.089 4.089 0 01-1.852-.498v.045C.8 7.64 2.221 9.27 4.085 9.646c-.334.09-.697.132-1.075.132-.262 0-.527-.014-.776-.068a4.131 4.131 0 003.831 2.812 8.323 8.323 0 01-5.084 1.722A7.73 7.73 0 010 14.188 11.668 11.668 0 006.29 16c7.545 0 11.67-6.154 11.67-11.488 0-.178-.006-.35-.015-.522A8.11 8.11 0 0020 1.894z"
        fill="#00ACED"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgTwitterActive
