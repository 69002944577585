import * as React from "react"

function SvgDownActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      {...props}
    >
      <defs>
        <path
          id="DownActive_svg__a"
          d="M11 3.556a.555.555 0 01-.125.348L6.432 9.293a.576.576 0 01-.887 0l-4.42-5.366a.546.546 0 01.088-.78.579.579 0 01.8.085l3.976 4.826 3.998-4.85a.578.578 0 01.8-.086c.14.111.213.271.213.434z"
        />
      </defs>
      <g transform="rotate(-90 6 6)" fill="none" fillRule="evenodd">
        <mask id="DownActive_svg__b" fill="#fff">
          <use xlinkHref="#DownActive_svg__a" />
        </mask>
        <use
          fill="#000"
          fillOpacity={0.25}
          fillRule="nonzero"
          xlinkHref="#DownActive_svg__a"
        />
        <g fill="#EA4C89" mask="url(#DownActive_svg__b)">
          <path d="M0 0h12v12H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgDownActive
