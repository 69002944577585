import * as React from "react"

function ChevronDown(props) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 6l4-4.518L9 6"
        stroke="#11142D"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronDown
