import * as React from "react"

function SvgIconBrazil(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#1F3D8F"
          d="M7 4.742a2.258 2.258 0 10-.002 4.517A2.258 2.258 0 007 4.742z"
        />
        <path
          fill="#FFD45D"
          d="M9.217 7.42a2.265 2.265 0 10-4.449-.855 2.265 2.265 0 004.449.855zm3.88-.42L7 10.839.903 7 7 3.161 13.097 7z"
        />
        <path
          fill="#009B3A"
          d="M14 .903v12.194a.903.903 0 01-.903.903H.903A.903.903 0 010 13.097V.903C0 .404.404 0 .903 0h12.194c.499 0 .903.404.903.903zM7 10.84L13.097 7 7 3.161.903 7 7 10.839z"
        />
        <path
          fill="#162A63"
          d="M7 9.258c.606 0 1.186-.244 1.61-.677H5.39A2.251 2.251 0 007 9.258z"
        />
        <path
          fill="#FFB844"
          d="M7 10.839L13.097 7l-.277-.174a4.957 4.957 0 01-3.788 1.755H8.61a2.251 2.251 0 01-3.22 0h-.421a4.96 4.96 0 01-3.788-1.755L.903 7 7 10.839z"
        />
        <path
          fill="#00802F"
          d="M13.097 7L7 10.839.903 7l.277-.174A4.957 4.957 0 010 3.613v9.484c0 .499.404.903.903.903h12.194a.903.903 0 00.903-.903V3.613a4.952 4.952 0 01-1.18 3.213l.277.174z"
        />
        <path
          fill="#006927"
          d="M13.097 13.097H.903A.903.903 0 010 12.194v.903c0 .499.404.903.903.903h12.194a.903.903 0 00.903-.903v-.903a.903.903 0 01-.903.903z"
        />
        <path
          fill="#00BA46"
          d="M13.097 0H.903A.903.903 0 000 .903v.903C0 1.308.404.903.903.903h12.194c.499 0 .903.405.903.903V.903A.903.903 0 0013.097 0z"
        />
      </g>
    </svg>
  )
}

export default SvgIconBrazil
