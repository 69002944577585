import * as React from "react"

function SvgTurnOffDefault(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <g fill="#585858" fillRule="nonzero">
        <path d="M12.6 1.701c-.415-.17-.897.008-1.078.4-.18.39.008.846.422 1.017 2.662 1.099 4.42 3.586 4.42 6.379 0 3.842-3.298 6.957-7.364 6.957-4.067 0-7.364-3.115-7.364-6.957 0-2.792 1.757-5.28 4.418-6.38.414-.17.603-.626.422-1.017-.181-.391-.663-.57-1.077-.399C2.147 3.045 0 6.085 0 9.497 0 14.193 4.03 18 9 18s9-3.807 9-8.503c0-3.413-2.148-6.453-5.4-7.796z" />
        <path d="M9 8.182c.452 0 .818-.333.818-.744V.744C9.818.333 9.452 0 9 0c-.452 0-.818.333-.818.744v6.694c0 .41.366.744.818.744z" />
      </g>
    </svg>
  )
}

export default SvgTurnOffDefault
