import * as React from "react"

function SvgActionDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="ActionDown_svg__a"
          d="M14.667 4.741a.741.741 0 01-.167.465L8.576 12.39a.765.765 0 01-.592.277.762.762 0 01-.591-.277L1.5 5.236a.727.727 0 01.116-1.04.77.77 0 011.067.113l5.301 6.435 5.332-6.467a.77.77 0 011.066-.114.737.737 0 01.284.578z"
        />
      </defs>
      <g transform="matrix(-1 0 0 1 16 0)" fill="none" fillRule="evenodd">
        <mask id="ActionDown_svg__b" fill="#fff">
          <use xlinkHref="#ActionDown_svg__a" />
        </mask>
        <use
          fill="#000"
          fillOpacity={0.25}
          fillRule="nonzero"
          xlinkHref="#ActionDown_svg__a"
        />
        <g fill="#EA4C89" mask="url(#ActionDown_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgActionDown
