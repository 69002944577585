import * as React from "react"

function SvgAnonimattaTwitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={12}
      viewBox="0 0 14 12"
      {...props}
    >
      <path
        d="M14 1.42a5.715 5.715 0 01-1.654.479A2.987 2.987 0 0013.609.225a5.523 5.523 0 01-1.82.733A2.81 2.81 0 009.692 0C8.103 0 6.824 1.36 6.824 3.029c0 .24.02.47.067.69C4.505 3.596 2.393 2.39.975.552a3.172 3.172 0 00-.393 1.53c0 1.05.512 1.979 1.275 2.517A2.725 2.725 0 01.56 4.226v.033c0 1.471.995 2.694 2.3 2.975-.234.068-.489.1-.753.1-.184 0-.37-.011-.543-.052.371 1.2 1.427 2.08 2.681 2.11A5.58 5.58 0 010 10.642 7.79 7.79 0 004.403 12c5.282 0 8.169-4.615 8.169-8.616a8.29 8.29 0 00-.01-.391A5.92 5.92 0 0014 1.421z"
        fill="#84858F"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgAnonimattaTwitter
