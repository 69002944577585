import * as React from "react"

function SvgInstagramActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="#517FA4" fillRule="nonzero">
        <path d="M19.95 5.88c-.048-1.063-.22-1.793-.466-2.426a4.88 4.88 0 00-1.157-1.774 4.915 4.915 0 00-1.77-1.153C15.92.281 15.193.11 14.131.063 13.06.012 12.72 0 10.004 0 7.288 0 6.948.012 5.88.059 4.818.105 4.088.277 3.455.523A4.876 4.876 0 001.68 1.68 4.92 4.92 0 00.528 3.45C.28 4.087.109 4.813.063 5.876.012 6.946 0 7.286 0 10.002c0 2.715.012 3.055.059 4.122.046 1.063.219 1.793.465 2.426a4.93 4.93 0 001.156 1.774c.5.508 1.106.903 1.77 1.153.638.246 1.364.418 2.427.464C6.944 19.988 7.284 20 10 20s3.056-.012 4.123-.059c1.063-.046 1.793-.218 2.426-.464a5.114 5.114 0 002.927-2.927c.246-.637.419-1.363.465-2.426.047-1.067.059-1.407.059-4.122s-.004-3.055-.05-4.122zm-1.802 8.166c-.043.977-.207 1.504-.344 1.856a3.32 3.32 0 01-1.9 1.898c-.351.137-.882.301-1.856.344-1.055.047-1.371.059-4.04.059-2.67 0-2.99-.012-4.04-.059-.978-.043-1.505-.207-1.857-.344a3.078 3.078 0 01-1.149-.746 3.107 3.107 0 01-.746-1.148c-.137-.352-.301-.883-.344-1.856-.047-1.055-.059-1.372-.059-4.04 0-2.669.012-2.99.059-4.04.043-.977.207-1.504.344-1.856a3.04 3.04 0 01.75-1.149 3.102 3.102 0 011.149-.746c.352-.137.883-.3 1.856-.344 1.055-.046 1.372-.058 4.04-.058 2.674 0 2.99.012 4.041.058.977.043 1.505.207 1.857.344.433.16.828.414 1.148.746.333.325.587.715.747 1.15.137.35.3.882.344 1.855.047 1.055.058 1.371.058 4.04 0 2.668-.011 2.98-.058 4.036z" />
        <path d="M10 4.348A5.653 5.653 0 004.348 10c0 3.12 2.532 5.652 5.652 5.652s5.652-2.531 5.652-5.652A5.653 5.653 0 0010 4.348zm0 9.318a3.666 3.666 0 110-7.332 3.666 3.666 0 010 7.332zm6.522-9.753a1.304 1.304 0 11-2.609 0 1.304 1.304 0 012.609 0z" />
      </g>
    </svg>
  )
}

export default SvgInstagramActive
