import * as React from "react"

function SvgEyeOpen(props) {
  return (
    <svg
      width={16}
      height={11}
      viewBox="0 0 16 11"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#52546B" fillRule="nonzero">
        <path d="M7.989 3.29a2.18 2.18 0 00-2.18 2.18 2.18 2.18 0 002.18 2.178 2.18 2.18 0 002.178-2.178A2.18 2.18 0 007.99 3.29z" />
        <path d="M7.989.023C4.357.023 1.256 2.28 0 5.47c1.256 3.188 4.357 5.446 7.989 5.446 3.634 0 6.732-2.258 7.988-5.446C14.721 2.28 11.623.023 7.99.023zm0 9.078a3.633 3.633 0 01-3.632-3.632A3.633 3.633 0 017.99 1.84a3.633 3.633 0 013.63 3.63 3.633 3.633 0 01-3.63 3.632z" />
      </g>
    </svg>
  )
}

export default SvgEyeOpen
