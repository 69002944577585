import * as React from "react"

function SvgLandingPageWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="#FFFFFE">
          <path d="M9.49 8c.17 0 .345.029.515.056.102.013.196.155.249.259.246.548.492 1.096.705 1.657.12.297-.1.58-.557 1.07-.144.156-.173.304-.065.484.722 1.18 1.739 2.032 3.083 2.535a.5.5 0 00.169.033c.115 0 .21-.053.3-.155.575-.66.739-.95.971-.95.058 0 .121.018.195.053 1.796.819 1.938.851 1.944 1.045.047 1.373-1.21 1.913-2.062 1.913l-.13-.004c-1.74-.059-4.509-1.368-6.388-4.515-.662-1.115-.557-2.386.44-3.263.19-.168.406-.218.63-.218" />
          <path d="M13.183 19.306c4.061-.65 6.865-4.439 6.278-8.477a7.39 7.39 0 00-7.315-6.358c-.449 0-.903.04-1.361.123-5.028.922-7.736 6.513-5.275 10.944.275.488.346.895.152 1.416-.222.631-.363 1.281-.565 1.993.891-.23 1.713-.434 2.53-.664a.82.82 0 01.212-.03c.137 0 .26.04.397.118 1.175.68 2.417 1.034 3.728 1.034.4 0 .805-.033 1.218-.099m7.68-9.033c.858 4.933-2.407 9.609-7.312 10.47-.527.09-1.05.134-1.565.134-1.382 0-2.72-.32-4.005-.948a.855.855 0 00-.55-.06C5.06 20.465 3.484 20.905 3 21c.105-.386.196-.725.286-1.064.307-1.084.62-2.161.907-3.246a.806.806 0 00-.044-.522 8.98 8.98 0 015.954-12.922A9.213 9.213 0 0112.204 3c4.353 0 7.945 3.192 8.66 7.273" />
        </g>
      </g>
    </svg>
  )
}

export default SvgLandingPageWhatsapp
