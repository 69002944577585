import * as React from "react"

function SvgMenuAdd(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      viewBox="0 0 20 21"
      {...props}
    >
      <path
        d="M10 .5A1.5 1.5 0 0111.5 2v7h7a1.5 1.5 0 010 3h-7.001l.001 7a1.5 1.5 0 01-3 0l-.001-7H1.5a1.5 1.5 0 010-3h7V2A1.5 1.5 0 0110 .5z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgMenuAdd
