import * as React from "react"

function SvgDown(props) {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5.51786 5L1 9"
        stroke="#808191"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    /*  <svg
      width={9}
      height={14}
      viewBox="0 0 9 14"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M14.667 4.741a.738.738 0 01-.167.465L8.576 12.39a.767.767 0 01-.592.277.764.764 0 01-.591-.277L1.5 5.236a.728.728 0 01.116-1.04.772.772 0 011.067.113l5.301 6.435 5.332-6.467a.772.772 0 011.066-.114.736.736 0 01.284.578z"
          id="Down_svg__a"
        />
      </defs>
      <g transform="rotate(-90 5.5 9.5)" fill="none" fillRule="evenodd">
        <mask id="Down_svg__b" fill="#fff">
          <use xlinkHref="#Down_svg__a" />
        </mask>
        <use
          fillOpacity={0.25}
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#Down_svg__a"
        />
        <g mask="url(#Down_svg__b)" fill="#5C5353">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg> */
  )
}

export default SvgDown
