import * as React from "react"

function SvgHeartWhite(props) {
  return (
    <svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M12 21c-.285 0-.56-.104-.773-.294a232.54 232.54 0 00-2.274-1.979l-.004-.003c-2.015-1.737-3.755-3.237-4.965-4.714C2.63 12.358 2 10.79 2 9.08c0-1.662.564-3.196 1.587-4.318a5.35 5.35 0 014-1.762c1.155 0 2.212.37 3.143 1.097.47.368.895.818 1.27 1.342.375-.524.8-.974 1.27-1.342C14.202 3.37 15.26 3 16.414 3a5.35 5.35 0 014 1.762C21.438 5.884 22 7.418 22 9.08c0 1.711-.63 3.278-1.983 4.93-1.211 1.477-2.95 2.977-4.966 4.714-.688.593-1.468 1.266-2.278 1.982-.214.19-.488.294-.773.294zM7.587 4.185c-1.213 0-2.328.49-3.14 1.38-.822.903-1.276 2.151-1.276 3.515 0 1.44.53 2.726 1.715 4.173 1.145 1.399 2.85 2.868 4.823 4.57l.004.002c.69.595 1.471 1.27 2.285 1.99.82-.722 1.602-1.397 2.294-1.993 1.973-1.701 3.677-3.17 4.823-4.569 1.185-1.447 1.714-2.734 1.714-4.173 0-1.364-.454-2.612-1.277-3.515a4.197 4.197 0 00-3.139-1.38c-.889 0-1.705.286-2.426.85-.642.502-1.09 1.138-1.352 1.582a.732.732 0 01-.635.365.732.732 0 01-.635-.365c-.262-.444-.71-1.08-1.352-1.582a3.857 3.857 0 00-2.426-.85z"
          id="heart-white_svg__a"
        />
      </defs>
      <g transform="translate(-2 -3)" fill="none" fillRule="evenodd">
        <mask id="heart-white_svg__b" fill="#fff">
          <use xlinkHref="#heart-white_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#heart-white_svg__a" />
        <g mask="url(#heart-white_svg__b)" fill="#FFF">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgHeartWhite
