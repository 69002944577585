import * as React from "react"

function SvgCheckFinish(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={42}
      viewBox="0 0 42 42"
      preserveAspectRatio="none"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={21} cy={21} r={21} fill="#FF78AC" />
        <path
          style={{ transform: "translate(9.5px, 9.5px) scale(0.022)" }}
          fill="#FFF"
          d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
        ></path>
      </g>
    </svg>
  )
}

export default SvgCheckFinish
