import * as React from "react"

function CloseSearch(props) {
  return (
    <svg
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      width={18}
      height={18}
      {...props}
    >
      <style />
      <path
        id="prefix__Layer"
        d="M11.5 23C5.14 23 0 17.86 0 11.5S5.14 0 11.5 0 23 5.14 23 11.5 17.86 23 11.5 23z"
        fill="#c4c4c4"
      />
      <path
        id="prefix__Layer"
        d="M7 16l9-9m0 9L7 7"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default CloseSearch
