import * as React from "react"

function SvgShared(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="shared_svg__a"
          d="M18.045 2C19.953 2 21.5 3.557 21.5 5.478c0 1.921-1.547 3.479-3.455 3.479a3.439 3.439 0 01-2.573-1.158l-6.255 3.49a3.5 3.5 0 01-.045 2.416l5.797 3.233a3.451 3.451 0 013.076-1.895c1.908 0 3.455 1.558 3.455 3.479 0 1.92-1.547 3.478-3.455 3.478-1.907 0-3.454-1.557-3.454-3.478 0-.22.02-.433.058-.64l-5.957-3.325a3.44 3.44 0 01-2.737 1.356c-1.908 0-3.455-1.557-3.455-3.478 0-1.921 1.547-3.478 3.455-3.478 1.138 0 2.149.554 2.778 1.41l6.17-3.443c-.2-.44-.312-.93-.312-1.446C14.59 3.558 16.138 2 18.045 2zm0 14.087a2.427 2.427 0 00-2.418 2.435 2.427 2.427 0 002.418 2.435c1.336 0 2.419-1.09 2.419-2.435s-1.083-2.435-2.419-2.435zM5.955 10a2.427 2.427 0 00-2.419 2.435 2.428 2.428 0 002.419 2.435c1.335 0 2.418-1.09 2.418-2.435S7.29 10 5.955 10zm12.09-6.957c-1.335 0-2.418 1.09-2.418 2.435s1.083 2.435 2.418 2.435a2.427 2.427 0 002.419-2.435 2.428 2.428 0 00-2.419-2.435z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="shared_svg__b" fill="#fff">
          <use xlinkHref="#shared_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#shared_svg__a" />
        <g fill="#52546b" mask="url(#shared_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgShared
