import * as React from "react"

function SvgWhatsappInactive(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.086 2.906A9.917 9.917 0 0010.042 0C4.552 0 .086 4.446.084 9.91a9.851 9.851 0 001.329 4.955L0 20l5.279-1.378a9.977 9.977 0 004.759 1.206h.004c5.488 0 9.956-4.446 9.958-9.91a9.83 9.83 0 00-2.914-7.012zm-7.044 15.248h-.004a8.282 8.282 0 01-4.212-1.148l-.302-.178-3.133.818.836-3.04-.197-.312A8.188 8.188 0 011.765 9.91c.002-4.541 3.715-8.236 8.28-8.236 2.21 0 4.29.858 5.852 2.415a8.175 8.175 0 012.422 5.828c-.002 4.542-3.715 8.237-8.277 8.237zm4.54-6.169c-.249-.124-1.472-.723-1.7-.806-.228-.082-.394-.123-.56.124-.166.248-.643.806-.788.971-.145.166-.29.186-.54.062-.248-.124-1.05-.385-2-1.229-.74-.656-1.24-1.467-1.384-1.715-.145-.248-.002-.37.109-.506.27-.332.539-.681.622-.847.083-.165.041-.31-.021-.433-.062-.124-.56-1.343-.767-1.84-.202-.482-.407-.417-.56-.424-.145-.007-.31-.009-.477-.009a.915.915 0 00-.663.31c-.228.248-.871.847-.871 2.066 0 1.219.891 2.396 1.016 2.562.124.165 1.754 2.666 4.25 3.739.594.255 1.057.407 1.419.521.596.189 1.138.162 1.567.098.478-.07 1.472-.599 1.68-1.177.207-.579.207-1.074.145-1.178-.062-.103-.228-.165-.477-.289z"
        fill="#84858F"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgWhatsappInactive
