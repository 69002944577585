import * as React from "react"

function SvgAnonimattaMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={81}
      viewBox="0 0 80 81"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <g fillRule="nonzero">
          <path
            fill="#FF91C1"
            d="M75.843 79.038H4.157c-1.733 0-3.138-1.388-3.138-3.1V34.674h77.962v41.264c0 1.712-1.404 3.1-3.137 3.1z"
          />
          <path
            fill="#FF7DB6"
            d="M72.706 34.674v41.264c0 1.712-1.405 3.1-3.138 3.1h6.275c1.733 0 3.137-1.388 3.137-3.1V34.674h-6.274z"
          />
          <path
            fill="#FF5492"
            d="M78.981 34.674L39.999 56.993 1.02 34.674 37.929 2.787a3.164 3.164 0 014.14 0l36.91 31.887z"
          />
          <path
            fill="#E0EFFF"
            d="M70.116 39.762L40 56.993 9.884 39.752V12.641h60.232z"
          />
          <path
            fill="#D4E8FF"
            d="M63.841 12.641v27.121L36.864 55.197 40 56.993l30.116-17.231V12.641z"
          />
          <path
            fill="#FF5492"
            d="M38.122 54.139L4.388 79.038h71.223l-33.734-24.9a3.17 3.17 0 00-3.755 0z"
          />
          <path
            fill="#FF91C1"
            d="M46.34 22.291c-2.831 0-5.266 1.857-6.34 4.52-1.076-2.663-3.51-4.52-6.34-4.52-3.827 0-6.93 3.393-6.93 7.58C26.73 39.453 40 44.744 40 44.744s13.136-5.363 13.267-14.873c.058-4.185-3.102-7.58-6.928-7.58z"
          />
          <path
            fill="#FF7DB6"
            d="M46.34 22.291a6.424 6.424 0 00-3.134.818c2.267 1.251 3.828 3.808 3.788 6.761-.09 6.506-6.264 11.07-10.158 13.306 1.825 1.034 3.163 1.568 3.163 1.568s13.138-5.363 13.269-14.873c.058-4.185-3.102-7.58-6.928-7.58z"
          />
          <g fill="#000">
            <path d="M79.843 34.68a1.165 1.165 0 00-.399-.856l-8.412-7.284V12.742c0-.64-.524-1.158-1.17-1.158H53.759l-11.007-9.53a4.329 4.329 0 00-5.658-.002l-11.009 9.532H9.982c-.646 0-1.17.518-1.17 1.158v13.797L.4 33.824c-.25.217-.4.542-.4.872V75.81c0 2.437 2.114 4.301 4.519 4.247h70.806c2.405.03 4.52-1.79 4.52-4.247V34.696c0-.006 0-.01-.002-.016zm-3.167-.176l-5.644 3.239v-8.126l5.644 4.887zm-38.04-30.71a1.969 1.969 0 012.574.001l8.995 7.789H29.64l8.997-7.79zM68.692 13.9v25.185L43.22 53.703c-2.139-1.705-4.449-1.728-6.595 0L11.152 39.085V13.9h57.54zM8.812 29.617v8.126l-5.644-3.24 5.644-4.886zM2.34 75.81V36.705l7.006 4.02c.033.022.067.041.102.06l25.14 14.425L4.14 77.733a1.94 1.94 0 01-1.8-1.923zm73.365 1.923l-13.18-9.75c-1.173-.867-2.626.949-1.4 1.856l10.681 7.901H8.038l30.717-22.722a1.978 1.978 0 012.334 0L56.178 66.18c1.172.867 2.626-.949 1.4-1.856l-12.32-9.114 25.05-14.375c.095-.039.183-.09.263-.15l6.934-3.98V75.81a1.94 1.94 0 01-1.801 1.923z" />
            <path d="M32.613 41.818c3.386 2.578 6.73 3.93 6.871 3.986.295.113.59.112.884-.004.14-.057 3.45-1.425 6.817-4.012 4.585-3.521 7.04-7.623 7.097-11.863.033-2.405-.893-4.733-2.54-6.386-1.503-1.509-3.463-2.34-5.517-2.34-2.492 0-4.797 1.238-6.303 3.28-1.838-2.492-5.034-3.816-8.1-3.06-1.42.35-.92 2.613.565 2.247 2.771-.683 5.436 1.111 6.449 3.624.383.953 1.789.953 2.172 0 .924-2.293 2.972-3.775 5.217-3.775 1.423 0 2.791.587 3.852 1.651 1.21 1.215 1.89 2.938 1.866 4.728-.105 7.657-9.822 12.557-12.027 13.57C37.694 42.467 27.9 37.632 27.9 29.91c0-1.06.238-2.112.69-3.041.633-1.306-1.447-2.37-2.11-1.003a9.335 9.335 0 00-.919 4.044c0 4.276 2.439 8.394 7.052 11.908z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgAnonimattaMail
