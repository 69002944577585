import * as React from "react"

function SvgAnonimattaLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={118}
      height={40}
      viewBox="0 0 118 40"
      {...props}
    >
      <path
        d="M8.57.76L11.472 40H7.095l-.645-9.88H4.515L3.916 40H0L2.718.76H8.57zm9.55 0l2.885 21.107h.046V.76h4.188V40h-5.072l-3.21-21.781h-.093V40h-3.862V.76h5.118zM32.274 0c.606 0 1.214.113 1.82.338A4.228 4.228 0 0135.7 1.407c.463.488.839 1.116 1.126 1.884.288.77.431 1.68.431 2.729v27.96c0 1.05-.143 1.96-.43 2.729-.288.769-.664 1.397-1.127 1.884a4.223 4.223 0 01-1.605 1.07 5.234 5.234 0 01-1.821.337h-.959a5.133 5.133 0 01-1.844-.338 4.222 4.222 0 01-1.582-1.069c-.463-.487-.839-1.115-1.126-1.884-.287-.768-.432-1.678-.432-2.728V6.02c0-1.05.145-1.96.432-2.729.287-.768.663-1.396 1.126-1.884.463-.487.99-.844 1.582-1.07A5.172 5.172 0 0131.315 0h.959zm11.44.38l2.962 21.175h.048V.38h4.3v39.367h-5.208L42.52 17.895h-.096v21.852H38.46V.38h5.255zm13.1 0v39.367h-4.697V.38h4.698zm26.05 0l3.014 39.367h-4.544l-.67-9.913h-2.008l-.622 9.913H73.97L76.79.38h6.075zm32.123 0L118 39.747h-4.543l-.67-9.913h-2.009l-.622 9.913h-4.065L108.913.38h6.074zM64.082.253l1.433 17.684h.095L67.14.253h6.065V39.62h-3.917V17.77h-.095l-1.91 21.851H63.89L62.076 17.77h-.095v21.85h-3.964V.253h6.065zm31.52 0v4.675h-3.36V39.62H87.49V4.928h-3.36V.253h11.472zm11.69 0v4.675h-3.36V39.62h-4.751V4.928h-3.36V.253h11.472zM31.843 4.67h-.095c-.448 0-.671.731-.671 2.194v26.272c0 .788.048 1.35.144 1.688.095.338.27.506.527.506h.096c.255 0 .43-.168.527-.506.096-.338.143-.9.143-1.688V6.864c0-1.463-.224-2.194-.67-2.194zM5.576 15.973h-.138l-.645 9.993h1.382l-.6-9.993zm74.18-.33h-.143l-.67 10.024h1.436l-.622-10.025zm32.123 0h-.143l-.67 10.024h1.435l-.622-10.025z"
        fill="#EA4C89"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgAnonimattaLogo
