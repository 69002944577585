import * as React from "react"

function SvgMobileSaveActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={20}
      height={19}
      viewBox="0 0 20 19"
      {...props}
    >
      <defs>
        <path id="MobileSaveActive_svg__a" d="M0 0h19.77v18.907H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="MobileSaveActive_svg__b" fill="#fff">
          <use xlinkHref="#MobileSaveActive_svg__a" />
        </mask>
        <path
          fill="#EA4C89"
          d="M9.885 0c-.381 0-.762.199-.958.596L6.701 5.108a1.07 1.07 0 01-.805.584l-4.981.724a1.068 1.068 0 00-.591 1.822l3.603 3.513c.252.245.367.599.308.945l-.851 4.96a1.07 1.07 0 001.052 1.252c.165 0 .333-.04.498-.126l4.454-2.342a1.077 1.077 0 01.994 0l4.454 2.342c.165.086.333.126.498.126a1.07 1.07 0 001.052-1.251l-.851-4.961c-.059-.346.056-.7.308-.945l3.603-3.513a1.068 1.068 0 00-.591-1.822l-4.981-.724a1.07 1.07 0 01-.805-.584L10.843.596A1.056 1.056 0 009.885 0m0 .889c.074 0 .128.033.161.1l2.227 4.513c.285.578.836.978 1.473 1.07l4.981.724a.17.17 0 01.145.122.17.17 0 01-.046.183l-3.604 3.513a1.961 1.961 0 00-.563 1.733l.85 4.96a.162.162 0 01-.034.142.197.197 0 01-.141.069.175.175 0 01-.083-.022l-4.455-2.343a1.98 1.98 0 00-.911-.224 1.98 1.98 0 00-.911.224L4.52 17.996a.189.189 0 01-.085.022.195.195 0 01-.141-.07.16.16 0 01-.034-.141l.85-4.96a1.96 1.96 0 00-.563-1.733L.944 7.601a.17.17 0 01-.046-.183.172.172 0 01.145-.122l4.98-.724a1.954 1.954 0 001.474-1.07L9.724.99a.168.168 0 01.161-.1"
          mask="url(#MobileSaveActive_svg__b)"
        />
      </g>
    </svg>
  )
}

export default SvgMobileSaveActive
