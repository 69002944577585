import * as React from "react"

function SvgAcountDefault(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      {...props}
    >
      <g fill="#585858" fillRule="nonzero">
        <path d="M9 4.697A4.308 4.308 0 004.697 9 4.308 4.308 0 009 13.303 4.308 4.308 0 0013.303 9 4.308 4.308 0 009 4.697zm0 7.53A3.23 3.23 0 015.773 9 3.23 3.23 0 019 5.773 3.23 3.23 0 0112.227 9 3.23 3.23 0 019 12.227z" />
        <path d="M17.576 6.95l-1.383-.3a7.568 7.568 0 00-.445-1.074l.766-1.191a.54.54 0 00-.072-.671l-2.156-2.156a.538.538 0 00-.67-.072l-1.192.766a7.453 7.453 0 00-1.074-.445l-.3-1.383A.54.54 0 0010.523 0H7.476a.536.536 0 00-.525.424L6.65 1.807c-.369.12-.728.27-1.074.445l-1.191-.766a.54.54 0 00-.671.072L1.558 3.714a.538.538 0 00-.072.67l.766 1.192a7.453 7.453 0 00-.445 1.074l-1.383.3A.54.54 0 000 7.477v3.048c0 .253.176.472.424.525l1.383.301c.12.369.27.728.445 1.074l-.766 1.191a.54.54 0 00.072.671l2.156 2.156a.538.538 0 00.67.072l1.192-.766c.346.176.705.325 1.074.445l.3 1.383a.538.538 0 00.526.424h3.048a.536.536 0 00.525-.424l.301-1.383c.369-.12.728-.27 1.074-.445l1.191.766a.538.538 0 00.671-.072l2.156-2.156a.538.538 0 00.072-.67l-.766-1.192c.176-.346.325-.705.445-1.074l1.383-.3a.538.538 0 00.424-.526V7.476a.536.536 0 00-.424-.525zm-.652 3.14l-1.268.276a.541.541 0 00-.404.381c-.14.5-.34.984-.596 1.437a.54.54 0 00.016.556l.702 1.092-1.542 1.542-1.092-.702a.537.537 0 00-.555-.016 6.45 6.45 0 01-1.438.596.535.535 0 00-.38.404l-.277 1.268H7.91l-.276-1.268a.541.541 0 00-.381-.404 6.47 6.47 0 01-1.437-.596.54.54 0 00-.556.016l-1.092.702-1.542-1.542.702-1.092a.54.54 0 00.016-.556 6.47 6.47 0 01-.596-1.437.535.535 0 00-.404-.38l-1.268-.277V7.91l1.268-.276a.541.541 0 00.404-.381c.14-.5.34-.984.596-1.437a.54.54 0 00-.016-.556l-.702-1.092 1.542-1.542 1.092.702a.537.537 0 00.555.016 6.45 6.45 0 011.438-.596.535.535 0 00.38-.404l.277-1.268h2.18l.276 1.268c.043.195.19.35.381.404.5.14.984.34 1.437.596a.54.54 0 00.556-.016l1.092-.702 1.542 1.542-.702 1.092a.54.54 0 00-.016.556c.256.453.456.937.596 1.437a.535.535 0 00.404.38l1.268.277v2.18z" />
      </g>
    </svg>
  )
}

export default SvgAcountDefault
