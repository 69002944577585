import * as React from "react"

function SvgMobileFeedDefault(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g stroke="#6E71A0" fill="none" fillRule="evenodd">
        <rect width={19} height={15} x={0.5} y={0.5} rx={3} />
        <rect width={19} height={1} x={0.5} y={19.5} rx={0.5} />
      </g>
    </svg>
  )
}

export default SvgMobileFeedDefault
