import * as React from "react"

function SvgPlusPostage(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="PlusPostage_svg__a"
          d="M8 1.333a.5.5 0 01.5.5V7.5l5.667.001a.5.5 0 010 1H8.499l.001 5.667a.5.5 0 01-1 0V8.499L1.832 8.5a.5.5 0 010-1H7.5V1.832a.5.5 0 01.5-.5z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="PlusPostage_svg__b" fill="#fff">
          <use xlinkHref="#PlusPostage_svg__a" />
        </mask>
        <path
          stroke="#fff"
          strokeWidth={0.667}
          d="M8.093 1.702l.025.013c.03.03.049.072.049.118h0l-.001 6h6a.166.166 0 01.167.167.166.166 0 01-.166.167h0l-6.001-.001v6a.169.169 0 01-.048.119c-.03.03-.072.048-.118.048a.166.166 0 01-.167-.166h0V8.166h-6a.168.168 0 01-.118-.048c-.03-.03-.048-.072-.048-.118a.166.166 0 01.166-.167h6v-6c.155-.118.243-.16.285-.118z"
        />
        <g fill="#fff" mask="url(#PlusPostage_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgPlusPostage
