import * as React from "react"

function SVGEmptyStateCreators(props) {
  return (
    <svg
      width={137}
      height={123}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.75 121.283c33.148 0 60.022-26.874 60.022-60.141C130.772 27.874 103.898 1 70.749 1S10.726 27.874 10.726 61.142c0 33.267 26.874 60.141 60.023 60.141z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M26.59 29.295h85.359c3.433 0 6.037 2.604 6.037 6.038v54.103c0 3.434-2.604 6.038-6.037 6.038H26.59c-3.433 0-6.038-2.604-6.038-6.038V35.333c0-3.434 2.842-6.038 6.038-6.038z"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M101.623 35.328l.726 58.411c0 1.996-1.633 3.537-3.629 3.537H41.76c-1.994 0-3.627-1.632-3.627-3.537v-75.1c0-1.995 1.633-3.537 3.628-3.537h41.268l18.594 20.226z"
        fill="#fff"
        stroke="#D6DCE8"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M75.048 27.347h-27.3a1.2 1.2 0 01-1.18-1.18 1.2 1.2 0 011.18-1.178h27.3a1.2 1.2 0 011.179 1.179 1.2 1.2 0 01-1.18 1.179zM58.268 33.877h-10.52a1.2 1.2 0 01-1.18-1.179 1.2 1.2 0 011.18-1.179h10.43a1.2 1.2 0 011.179 1.18c0 .634-.544 1.178-1.089 1.178zM83.03 15.102v16.145c0 2.267 1.995 4.081 4.262 4.081h14.331M94.023 47.67H48.088c-.423 0-.846.424-.846 1.017 0 .509.339 1.017.846 1.017H93.94c.592 0 .93-.424.93-1.017 0-.509-.338-1.017-.846-1.017z"
        fill="#D6DCE8"
      />
      <path
        d="M119.644 63.983v26.638c0 3.433-2.723 6.274-6.275 6.274H25.643c-3.433 0-6.275-2.841-6.275-6.274V61.734"
        stroke="#1676EE"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.185 49.066H84.482c-2.723 0-5.327.947-7.34 2.604l-7.221 5.92c-2.013 1.657-4.618 2.604-7.34 2.604H31.325a5.81 5.81 0 00-5.801 5.801c0 .237 0 .592.118.83l5.801 30.543c.474 2.842 2.841 4.973 5.801 4.973h66.89c2.841 0 5.327-2.013 5.801-4.854l8.05-41.791c.592-3.197-1.539-6.038-4.735-6.63h-1.066z"
        fill="#fff"
      />
      <path
        d="M124.616 40.897h-35.99c-3.552 0-6.867 1.184-9.59 3.433l-9.47 7.696c-2.605 2.13-6.038 3.433-9.59 3.433H19.25c-4.143 0-7.458 3.433-7.458 7.458 0 .355 0 .71.118 1.066l7.459 39.897c.591 3.788 3.788 6.511 7.458 6.511h87.134c3.789 0 6.867-2.604 7.459-6.393l10.536-54.577c.711-4.144-2.012-7.814-6.156-8.642-.355.118-.829.118-1.184.118z"
        fill="#fff"
        stroke="#D6DCE8"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M132 36.89a4.1 4.1 0 100-8.201 4.1 4.1 0 000 8.2zM127.9 23.59a2.8 2.8 0 100-5.601 2.8 2.8 0 000 5.6zM11.5 32.79a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6zM5.2 94.39a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4z"
        fill="#F1F3F9"
      />
      <path
        d="M123.417 118.2l-17.394-17.36c-18.36 13.502-43.531.338-43.531-21.698 0-14.997 12.223-27.148 27.2-27.148 21.646 0 34.932 24.303 22.322 42.674l17.49 17.456a4.273 4.273 0 010 6.076 4.293 4.293 0 01-6.087 0zM89.693 60.625c-10.243 0-18.601 8.342-18.601 18.565 0 16.443 19.712 24.351 31.018 13.79 12.803-11.958 4.251-32.355-12.417-32.355z"
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M87.809 83.24a9.377 9.377 0 01-.097-1.205c0-1.591.676-3.038 2.367-4.292l1.45-1.06c.918-.676 1.304-1.447 1.304-2.363 0-1.35-.966-2.652-2.995-2.652-2.126 0-3.044 1.687-3.044 3.375 0 .337.048.675.097.964l-3.769-.144a6.416 6.416 0 01-.145-1.35c0-3.231 2.416-6.317 6.813-6.317 4.638 0 7.053 2.893 7.053 5.93 0 2.364-1.207 4.003-2.947 5.257l-1.208.868c-1.063.723-1.594 1.687-1.594 2.893v.193h-3.285v-.097zm1.69 1.93a2.369 2.369 0 012.368 2.362 2.369 2.369 0 01-2.367 2.363 2.37 2.37 0 01-2.368-2.363 2.37 2.37 0 012.368-2.363z"
        fill="#989FB0"
      />
    </svg>
  )
}

export default SVGEmptyStateCreators
