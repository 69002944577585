import * as React from "react"

function SvgMoneyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={33}
      viewBox="0 0 32 33"
      {...props}
    >
      <defs>
        <path id="money-icon_svg__a" d="M0 .209h31.111v31.198H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#547689"
          d="M1.481 16.192c0-7.42 4.763-13.516 10.787-14.072C7.843 4.637 4.78 10 4.78 16.192c0 6.194 3.064 11.556 7.488 14.072-6.024-.555-10.787-6.652-10.787-14.072"
        />
        <path
          fill="#DFEDF8"
          d="M26.678 26.852c-2.342 2.8-5.443 4.342-8.732 4.342-6.846 0-12.416-6.73-12.416-15.002 0-8.263 5.559-14.987 12.396-15.001h.023c3.287 0 6.387 1.542 8.729 4.342 2.34 2.8 3.683 6.685 3.683 10.66 0 3.974-1.343 7.858-3.683 10.659"
        />
        <path
          fill="#020303"
          d="M17.946 15.451c-.982 0-1.855-.833-2.122-2.028-.28-1.248.159-2.558 1.07-3.185.836-.578 1.87-.407 2.567.425a.74.74 0 101.136-.951 3.55 3.55 0 00-1.91-1.2V7.399a.74.74 0 00-1.482 0V8.5a3.433 3.433 0 00-1.152.519c-1.396.961-2.084 2.905-1.675 4.728.42 1.876 1.888 3.186 3.568 3.186.982 0 1.854.834 2.122 2.029.28 1.247-.16 2.557-1.07 3.184-.838.578-1.87.408-2.568-.424a.74.74 0 10-1.135.951c.534.637 1.203 1.046 1.91 1.214v1.099a.741.741 0 001.481 0v-1.11a3.418 3.418 0 001.154-.51c1.394-.962 2.083-2.907 1.674-4.729-.42-1.875-1.888-3.186-3.568-3.186"
        />
        <g transform="translate(0 .384)">
          <mask id="money-icon_svg__b" fill="#fff">
            <use xlinkHref="#money-icon_svg__a" />
          </mask>
          <path
            fill="#020303"
            d="M1.481 15.808c0-7.42 4.763-13.516 10.787-14.072C7.843 4.253 4.78 9.615 4.78 15.808c0 6.194 3.064 11.555 7.488 14.072-6.024-.555-10.787-6.652-10.787-14.072m28.506-4.36a.741.741 0 00-.728.893c.24 1.129.37 2.293.37 3.467 0 3.74-1.263 7.396-3.466 10.032-2.203 2.635-5.122 4.086-8.217 4.086-6.443 0-11.684-6.333-11.684-14.118 0-7.777 5.232-14.104 11.665-14.117h.018l.003-.001c3.094 0 6.012 1.452 8.215 4.086a14.086 14.086 0 012.011 3.254.748.748 0 00.68.446.75.75 0 00.683-1.057A15.637 15.637 0 0027.3 4.827C24.846 1.892 21.436.208 17.946.208l-.019.001h-4.762C5.905.21 0 7.206 0 15.808s5.906 15.6 13.165 15.6h4.781c3.49 0 6.9-1.684 9.354-4.618 2.458-2.94 3.811-6.84 3.811-10.982a18.4 18.4 0 00-.392-3.776.744.744 0 00-.732-.583"
            mask="url(#money-icon_svg__b)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgMoneyIcon
