import * as React from "react"

function SvgCardboard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={15}
      viewBox="0 0 21 15"
      {...props}
    >
      <g fill="#FFF" fillRule="nonzero">
        <path d="M20.385 0H.615C.275 0 0 .257 0 .575v3.462c0 .318.275.575.615.575h.62v9.813c0 .318.276.575.616.575h17.298c.34 0 .615-.257.615-.575V4.612h.62c.34 0 .616-.257.616-.575V.575C21 .257 20.725 0 20.385 0zm-1.851 13.85H2.466V4.613h16.068v9.239zM19.77 3.464H1.23V1.149h18.54v2.314z" />
        <path d="M9.22 9.706h2.56c1.056 0 1.916-.792 1.916-1.765 0-.973-.86-1.765-1.917-1.765H9.221c-1.057 0-1.917.792-1.917 1.765 0 .973.86 1.765 1.917 1.765zm0-2.356h2.56c.353 0 .642.265.642.591 0 .326-.289.592-.643.592H9.221c-.354 0-.643-.266-.643-.592 0-.326.289-.591.643-.591z" />
      </g>
    </svg>
  )
}

export default SvgCardboard
