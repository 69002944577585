import {
  AcountActive,
  AcountDefault,
  ActionDown,
  AfiliatedIcon,
  AlertError,
  AnonimattaEdit,
  AnonimattaInstagram,
  AnonimattaLogo,
  AnonimattaMail,
  AnonimattaTwitter,
  AnonimattaWhatsapp,
  BackArrow,
  BannerAdmin,
  BannerAdminWhite,
  Bill,
  Calendar,
  Cardboard,
  Cash,
  Chart,
  CheckFinish,
  CheckWait,
  ChevronDown,
  ChipElectronics,
  Cleansing,
  Clipboard,
  CloseModal,
  CloseSearch,
  CollaborativeWork,
  Comment,
  CommentWhite,
  Communication,
  CompleteAdvisory,
  ContainerFinish,
  ContainerWait,
  Courses,
  CreatorInstagram,
  CreatorMail,
  CreatorTwitter,
  CreatorWhatsapp,
  CreditCard,
  CreditCardFinish,
  CreditCardWait,
  CustomUserFinish,
  CustomUserWait,
  Delivery,
  Down,
  DownActive,
  EasyAccess,
  Economy,
  EditPink,
  EmptyState,
  EmptyStateCreators,
  EnhancedSecurity,
  EssayAdmin,
  EssayAdminWhite,
  EyeBlocked,
  EyeClose,
  EyeEdit,
  EyeOpen,
  Facebook,
  FacebookInactive,
  FavoriteHeart,
  FeedContent,
  Flexibility,
  FlexibleConsumption,
  FullFlexibility,
  FullScreen,
  Heart,
  HeartFilled,
  HeartWhite,
  HomeFinish,
  HomeWait,
  IcDelete,
  IconBrazil,
  IconSpain,
  IconUnitedKingdom,
  Inbox,
  Instagram,
  InstagramActive,
  InstagramInactive,
  Investiment,
  LandingPageFacebook,
  LandingPageFooterLogo,
  LandingPageInstagram,
  LandingPageTwitter,
  LandingPageWhatsapp,
  LandingPageYoutube,
  LargeArea,
  LargeEmployee,
  LargeStore,
  Limitless,
  Linkedin,
  Lock,
  Logo,
  LogoAlgarWhite,
  LogoWhite,
  LowCost,
  MailFinish,
  MailWait,
  Marketing,
  MediumArea,
  MediumEmployee,
  MediumStore,
  Menu,
  MenuAdd,
  MobileEssayActive,
  MobileEssayDefault,
  MobileFeedActive,
  MobileFeedDefault,
  MobileSaveActive,
  MobileSaveDefault,
  Money,
  MoneyIcon,
  MyDocuments,
  MySelfie,
  NotResult,
  Order,
  PaymentComplete,
  Performance,
  PhotoFinish,
  PhotoWait,
  PlusPostage,
  PostAdmin,
  PostAdminWhite,
  PriceTag,
  Productivity,
  Protection,
  RemovePhoto,
  Search,
  SearchIcon,
  SearchModal,
  SellPanel,
  Send,
  Shared,
  SharedPost,
  Shield,
  SmallArea,
  SmallEmployee,
  SmallStore,
  Success,
  SuccessPlanCanceled,
  Support,
  TokenConfirmed,
  TooltipInfo,
  TotalSecurity,
  TurnOff,
  TurnOffActive,
  TurnOffDefault,
  TwitterActive,
  TwitterInactive,
  UserAdmin,
  UserAdminWhite,
  UserFinish,
  UserMenu,
  UserOutline,
  UserWait,
  WhatsappActive,
  WhatsappInactive,
  Youtube,
} from "./components"
import Theater from "./components/Theater"

export const Icons = {
  Theater,
  Logo,
  Menu,
  Delivery,
  Cleansing,
  LogoWhite,
  Facebook,
  FacebookInactive,
  Instagram,
  Linkedin,
  Youtube,
  Calendar,
  Chart,
  ChevronDown,
  Cash,
  Money,
  Courses,
  Marketing,
  PriceTag,
  SmallStore,
  MediumStore,
  LargeStore,
  Investiment,
  LargeArea,
  LargeEmployee,
  MediumArea,
  MediumEmployee,
  SmallArea,
  SmallEmployee,
  Support,
  CompleteAdvisory,
  EnhancedSecurity,
  TotalSecurity,
  Shield,
  Productivity,
  Economy,
  Communication,
  CollaborativeWork,
  Protection,
  Performance,
  FlexibleConsumption,
  Flexibility,
  EasyAccess,
  ChipElectronics,
  LowCost,
  Limitless,
  FullFlexibility,
  LogoAlgarWhite,
  Search,
  Send,
  NotResult,
  SearchModal,
  Comment,
  Heart,
  HeartFilled,
  Shared,
  FavoriteHeart,
  EyeOpen,
  EyeClose,
  Success,
  AnonimattaEdit,
  Cardboard,
  AnonimattaInstagram,
  AnonimattaTwitter,
  AnonimattaWhatsapp,
  Down,
  TurnOff,
  InstagramActive,
  InstagramInactive,
  TwitterActive,
  TwitterInactive,
  WhatsappActive,
  WhatsappInactive,
  EmptyState,
  Bill,
  Clipboard,
  CreditCard,
  CloseModal,
  EditPink,
  AnonimattaLogo,
  BannerAdmin,
  BannerAdminWhite,
  PostAdmin,
  PostAdminWhite,
  EssayAdmin,
  EssayAdminWhite,
  UserAdmin,
  UserAdminWhite,
  ActionDown,
  UserMenu,
  MobileEssayDefault,
  MobileEssayActive,
  MobileFeedDefault,
  MobileFeedActive,
  MobileSaveDefault,
  MobileSaveActive,
  MenuAdd,
  AcountActive,
  AcountDefault,
  TurnOffActive,
  TurnOffDefault,
  DownActive,
  EyeBlocked,
  FullScreen,
  CommentWhite,
  HeartWhite,
  IcDelete,
  Order,
  EyeEdit,
  Inbox,
  BackArrow,
  TooltipInfo,
  AnonimattaMail,
  MyDocuments,
  MySelfie,
  CreatorInstagram,
  CreatorWhatsapp,
  CreatorTwitter,
  CreatorMail,
  IconBrazil,
  IconSpain,
  IconUnitedKingdom,
  UserOutline,
  UserWait,
  PhotoWait,
  MailWait,
  UserFinish,
  PhotoFinish,
  MailFinish,
  AfiliatedIcon,
  FeedContent,
  MoneyIcon,
  SellPanel,
  LandingPageFooterLogo,
  LandingPageYoutube,
  LandingPageTwitter,
  LandingPageFacebook,
  LandingPageWhatsapp,
  LandingPageInstagram,
  AlertError,
  PaymentComplete,
  Lock,
  PlusPostage,
  RemovePhoto,
  CreditCardFinish,
  CreditCardWait,
  CheckFinish,
  CheckWait,
  ContainerFinish,
  ContainerWait,
  HomeFinish,
  HomeWait,
  CustomUserFinish,
  CustomUserWait,
  Theater,
  SearchIcon,
  CloseSearch,
  EmptyStateCreators,
}
