import * as React from "react"

function SvgSearch(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M19.734 18.448l-5.16-5.16a8.182 8.182 0 10-1.286 1.285l5.16 5.16a.906.906 0 001.286 0 .91.91 0 000-1.285zM8.182 14.545a6.363 6.363 0 110-12.727 6.363 6.363 0 010 12.727z"
          id="search_svg__a"
        />
      </defs>
      <use
        fill="#52546b"
        fillRule="nonzero"
        xlinkHref="#search_svg__a"
        transform="translate(2 2)"
      />
    </svg>
  )
}

export default SvgSearch
