import * as React from "react"

function SvgSellPanel(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={30}
      viewBox="0 0 32 30"
      {...props}
    >
      <defs>
        <path id="sell-panel_svg__a" d="M0 29.607h32V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#DFEDF8"
          d="M28.88.676H3.113c-.98 0-1.78.8-1.78 1.787v12.19l4.334-4.333c.26-.26.68-.26.94 0l3.347 3.346c.4-.273.88-.426 1.4-.426.506 0 .98.153 1.38.413l2.592-2.593a2.457 2.457 0 01-.34-1.24 2.523 2.523 0 012.52-2.52 2.522 2.522 0 012.52 2.52c0 .453-.126.873-.34 1.246l2.974 2.974c.367-.207.78-.334 1.226-.334.46 0 .887.134 1.26.354l5.52-5.52V2.47c0-.994-.806-1.794-1.786-1.794"
        />
        <path
          fill="#547689"
          d="M30.666 22.993H1.333V16.54l4.8-4.807L9.08 14.68a2.532 2.532 0 00-.246 1.08 2.522 2.522 0 002.52 2.52 2.524 2.524 0 002.52-2.52c0-.4-.1-.767-.26-1.107l2.652-2.647c.367.207.787.34 1.24.34.454 0 .867-.133 1.24-.34l2.968 2.967c-.214.374-.347.8-.347 1.26a2.522 2.522 0 002.52 2.52 2.522 2.522 0 002.52-2.52c0-.447-.127-.86-.327-1.22l4.586-4.587v12.567z"
        />
        <path
          fill="#A7B1BB"
          d="M4.02 1.333h-.898c-.98 0-1.78.8-1.78 1.787v11.533l2.678-2.678V1.333z"
        />
        <path
          fill="#3B5767"
          d="M29.346 20.743H6.478a2.458 2.458 0 01-2.458-2.457v-4.429L1.341 16.54v6.453h29.334v-2.645a2.436 2.436 0 01-1.329.395"
        />
        <mask id="sell-panel_svg__b" fill="#fff">
          <use xlinkHref="#sell-panel_svg__a" />
        </mask>
        <path
          fill="#F28FBB"
          d="M13.848 25.349h4.32v-1.716h-4.32z"
          mask="url(#sell-panel_svg__b)"
        />
        <path
          fill="#E84C89"
          d="M11.2 13.918c1.095 0 1.985.89 1.985 1.984 0 1.094-.89 1.984-1.984 1.984a1.986 1.986 0 01-1.985-1.984c0-1.094.89-1.984 1.985-1.984m6.305-6.078c1.095 0 1.985.89 1.985 1.983 0 1.095-.89 1.984-1.985 1.984a1.987 1.987 0 01-1.984-1.984c0-1.093.89-1.984 1.984-1.984m6.38 6.407c1.095 0 1.985.89 1.985 1.984 0 1.094-.89 1.984-1.985 1.984a1.987 1.987 0 01-1.984-1.984c0-1.094.89-1.984 1.984-1.984"
          mask="url(#sell-panel_svg__b)"
        />
        <path
          fill="#010202"
          d="M30.666 22.993H1.333V16.54l4.8-4.807L9.08 14.68a2.532 2.532 0 00-.246 1.08 2.522 2.522 0 002.52 2.52 2.524 2.524 0 002.52-2.52c0-.4-.1-.767-.26-1.107l2.652-2.647c.367.207.787.34 1.24.34.454 0 .867-.133 1.24-.34l2.968 2.967c-.214.374-.347.8-.347 1.26a2.522 2.522 0 002.52 2.52 2.522 2.522 0 002.52-2.52c0-.447-.127-.86-.327-1.22l4.586-4.587v12.567zm-14.18 2.115h-.973a.669.669 0 01-.667-.666c0-.368.3-.667.667-.667h.974c.366 0 .667.3.667.667 0 .366-.3.666-.667.666zm-5.133-10.535c.654 0 1.187.527 1.187 1.187a1.19 1.19 0 01-1.187 1.187 1.19 1.19 0 01-1.186-1.187c0-.66.533-1.187 1.186-1.187zm6.154-5.94a1.19 1.19 0 01.846 2.02c-.007.007-.007.007-.013.007 0 .006 0 .006-.007.013-.213.207-.506.34-.826.34a1.2 1.2 0 01-.82-.333l-.04-.04a1.189 1.189 0 01.86-2.006zm6.38 6.407a1.19 1.19 0 01-.001 2.38 1.19 1.19 0 010-2.38zM28.88 0h-2.594a.666.666 0 100 1.333h2.594c.98 0 1.786.8 1.786 1.793V8.54l-5.52 5.52a2.47 2.47 0 00-1.26-.354c-.446 0-.86.127-1.226.334l-2.973-2.974c.213-.373.34-.793.34-1.246 0-1.387-1.127-2.52-2.52-2.52s-2.52 1.133-2.52 2.52c0 .453.126.873.34 1.24l-2.594 2.593c-.4-.26-.873-.413-1.38-.413-.52 0-1 .153-1.4.427L6.607 10.32a.664.664 0 00-.94 0l-4.333 4.333V3.12c0-.987.8-1.787 1.78-1.787h18.6A.666.666 0 1021.715 0h-18.6A3.113 3.113 0 000 3.114v19.552c0 .627.186 1.234.54 1.747 0 .007.013.013.013.02a3.303 3.303 0 00.367.441c.14.14.3.272.466.386.06.04.127.067.194.1.113.067.22.133.34.186.046.02.093.027.14.04.333.127.686.2 1.067.2h7.9v2.487H8.114c-.368 0-.667.3-.667.667 0 .373.3.666.667.666h15.772a.664.664 0 00.667-.666c0-.367-.3-.667-.667-.667h-2.913v-2.487h7.907a3.125 3.125 0 002.88-1.926.81.81 0 00.02-.067 3.15 3.15 0 00.22-1.127V3.126C32 1.4 30.6 0 28.88 0z"
          mask="url(#sell-panel_svg__b)"
        />
        <path
          fill="#020303"
          d="M3.869 4.66h4.335a.667.667 0 000-1.334H3.869a.667.667 0 000 1.334m6.054 1.083H3.869a.667.667 0 000 1.333h6.054a.667.667 0 100-1.333"
          mask="url(#sell-panel_svg__b)"
        />
      </g>
    </svg>
  )
}

export default SvgSellPanel
