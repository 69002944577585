import * as React from "react"

function SvgRemovePhoto(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} fill="#3f8cfe" fillOpacity={1} />
        <g stroke="#FFFF" strokeLinecap="round">
          <path d="M8.5 8.5l7 7M15.5 8.5l-7 7" />
        </g>
      </g>
    </svg>
  )
}

export default SvgRemovePhoto
