import * as React from "react"

function SvgHeart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="heart_svg__a"
          d="M10 18a1.16 1.16 0 01-.773-.294c-.808-.715-1.587-1.386-2.274-1.979l-.004-.003c-2.015-1.737-3.755-3.237-4.965-4.714C.63 9.358 0 7.79 0 6.08c0-1.662.564-3.196 1.587-4.318a5.35 5.35 0 014-1.762C6.742 0 7.8.37 8.73 1.097c.47.368.895.818 1.27 1.342.375-.524.8-.974 1.27-1.342C12.202.37 13.26 0 14.414 0a5.35 5.35 0 014 1.762C19.438 2.884 20 4.418 20 6.08c0 1.711-.63 3.278-1.983 4.93-1.211 1.477-2.95 2.977-4.966 4.714-.688.593-1.468 1.266-2.278 1.982-.214.19-.488.294-.773.294zM5.587 1.185a4.2 4.2 0 00-3.14 1.38c-.822.903-1.276 2.151-1.276 3.515 0 1.44.53 2.726 1.715 4.173 1.145 1.399 2.85 2.868 4.823 4.57l.004.002c.69.595 1.471 1.27 2.285 1.99.82-.722 1.602-1.397 2.294-1.993 1.973-1.701 3.677-3.17 4.823-4.569C18.3 8.806 18.829 7.52 18.829 6.08c0-1.364-.454-2.612-1.277-3.515a4.195 4.195 0 00-3.139-1.38c-.889 0-1.705.286-2.426.85-.642.502-1.09 1.138-1.352 1.582-.135.229-.372.365-.635.365s-.5-.136-.635-.365c-.262-.444-.71-1.08-1.352-1.582a3.855 3.855 0 00-2.426-.85z"
        />
      </defs>
      <use
        fill="#52546b"
        fillRule="nonzero"
        xlinkHref="#heart_svg__a"
        transform="translate(2 3)"
      />
    </svg>
  )
}

export default SvgHeart
