import * as React from "react"

export default function Theater(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props?.fillColor ? props.fillColor : "#BCBCBB"}
        fillRule="evenodd"
        clip-Rule="evenodd"
        d="M11.25 2.5C11.25 1.83696 11.5134 1.20107 11.9822 0.732233C12.4511 0.263392 13.087 0 13.75 0H17.5C18.163 0 18.7989 0.263392 19.2678 0.732233C19.7366 1.20107 20 1.83696 20 2.5V6.25C20 6.91304 19.7366 7.54893 19.2678 8.01777C18.7989 8.48661 18.163 8.75 17.5 8.75H13.75C13.087 8.75 12.4511 8.48661 11.9822 8.01777C11.5134 7.54893 11.25 6.91304 11.25 6.25V2.5ZM17.5 2.5H13.75V6.25H17.5V2.5ZM2.5 11.25C1.83696 11.25 1.20107 11.5134 0.732233 11.9822C0.263392 12.4511 0 13.087 0 13.75V17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H6.25C6.91304 20 7.54893 19.7366 8.01777 19.2678C8.48661 18.7989 8.75 18.163 8.75 17.5V13.75C8.75 13.087 8.48661 12.4511 8.01777 11.9822C7.54893 11.5134 6.91304 11.25 6.25 11.25H2.5ZM2.5 13.75H6.25V17.5H2.5V13.75ZM13.75 11.25C13.087 11.25 12.4511 11.5134 11.9822 11.9822C11.5134 12.4511 11.25 13.087 11.25 13.75V17.5C11.25 18.163 11.5134 18.7989 11.9822 19.2678C12.4511 19.7366 13.087 20 13.75 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V13.75C20 13.087 19.7366 12.4511 19.2678 11.9822C18.7989 11.5134 18.163 11.25 17.5 11.25H13.75ZM13.75 13.75H17.5V17.5H13.75V13.75ZM2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V6.25C0 6.91304 0.263392 7.54893 0.732233 8.01777C1.20107 8.48661 1.83696 8.75 2.5 8.75H6.25C6.91304 8.75 7.54893 8.48661 8.01777 8.01777C8.48661 7.54893 8.75 6.91304 8.75 6.25V2.5C8.75 1.83696 8.48661 1.20107 8.01777 0.732233C7.54893 0.263392 6.91304 0 6.25 0H2.5ZM2.5 2.5H6.25V6.25H2.5V2.5Z"
      />
    </svg>
  )
}
