import * as React from "react"

function SvgSearchModal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M15.787 14.759l-4.127-4.128a6.547 6.547 0 10-1.029 1.028l4.127 4.127a.727.727 0 001.029-1.028zm-9.242-3.123a5.091 5.091 0 115.091-5.091 5.091 5.091 0 01-5.091 5.091z"
        fill="#52546b"
      />
    </svg>
  )
}

export default SvgSearchModal
