import * as React from "react"

function SvgMyDocuments(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      {...props}
    >
      <defs>
        <path id="MyDocuments_svg__a" d="M0 .02h79.983v66.554H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <path
          fill="#54768A"
          d="M76.873 34.349L20.82 7.407a3.691 3.691 0 00-4.926 1.728L1.382 39.33a3.69 3.69 0 001.728 4.926l56.052 26.942a3.691 3.691 0 004.926-1.728L78.6 39.275a3.69 3.69 0 00-1.728-4.926"
        />
        <g transform="translate(0 6.005)">
          <mask id="MyDocuments_svg__b" fill="#fff">
            <use xlinkHref="#MyDocuments_svg__a" />
          </mask>
          <path
            fill="#000"
            d="M19.224 2.052c-.299 0-.598.05-.888.152A2.663 2.663 0 0016.81 3.57L2.297 33.764c-.31.644-.35 1.37-.113 2.045a2.653 2.653 0 001.366 1.526L59.6 64.277c.645.31 1.371.35 2.045.113a2.662 2.662 0 001.527-1.366L77.686 32.83c.31-.644.35-1.37.113-2.045a2.657 2.657 0 00-1.366-1.526L20.38 2.317a2.671 2.671 0 00-1.157-.265m41.533 64.522c-.696 0-1.39-.157-2.035-.467L2.67 39.165a4.674 4.674 0 01-2.402-2.684 4.675 4.675 0 01.199-3.597L14.98 2.69A4.674 4.674 0 0117.664.288a4.675 4.675 0 013.597.2l56.051 26.94a4.674 4.674 0 012.403 2.685 4.676 4.676 0 01-.2 3.597L65.004 63.903a4.675 4.675 0 01-2.684 2.403 4.712 4.712 0 01-1.562.268"
            mask="url(#MyDocuments_svg__b)"
          />
        </g>
        <path
          fill="#E0EFFF"
          d="M73.726 14.343h-62.19a3.692 3.692 0 00-3.692 3.692v33.5a3.691 3.691 0 003.692 3.692h62.19a3.691 3.691 0 003.691-3.691V18.035a3.691 3.691 0 00-3.691-3.692"
        />
        <path
          fill="#000"
          d="M11.536 15.359a2.678 2.678 0 00-2.676 2.676v33.5a2.68 2.68 0 002.676 2.677h62.19c1.476 0 2.676-1.2 2.676-2.676V18.035c0-1.476-1.2-2.676-2.676-2.676h-62.19zm62.19 40.883h-62.19a4.712 4.712 0 01-4.707-4.706V18.035a4.713 4.713 0 014.707-4.707h62.19a4.713 4.713 0 014.707 4.707v33.5a4.712 4.712 0 01-4.707 4.707z"
        />
        <path
          fill="#EA4C89"
          d="M27.38 29.806a5.078 5.078 0 11-10.156 0 5.078 5.078 0 0110.156 0m2.595 15.036c2.135 0 3.325-2.469 1.994-4.138a12.335 12.335 0 00-9.667-4.658c-3.913 0-7.402 1.82-9.666 4.658-1.331 1.67-.141 4.138 1.994 4.138h15.345z"
        />
        <path
          fill="#000"
          d="M22.302 25.743a4.067 4.067 0 00-4.062 4.063c0 2.24 1.822 4.062 4.062 4.062s4.063-1.822 4.063-4.062-1.823-4.063-4.063-4.063m0 10.156a6.053 6.053 0 01-4.308-1.785 6.054 6.054 0 01-1.785-4.308 6.05 6.05 0 011.785-4.308 6.05 6.05 0 014.308-1.785c1.628 0 3.158.634 4.309 1.785a6.053 6.053 0 011.784 4.308 6.05 6.05 0 01-1.784 4.308 6.054 6.054 0 01-4.309 1.785"
        />
        <path
          fill="#000"
          d="M22.302 37.061c-3.47 0-6.705 1.559-8.872 4.276a1.502 1.502 0 00-.183 1.62c.262.545.78.87 1.383.87h15.345c.604 0 1.12-.325 1.382-.87a1.5 1.5 0 00-.182-1.62 11.294 11.294 0 00-8.873-4.276m7.673 8.796H14.63a3.538 3.538 0 01-3.212-2.018 3.535 3.535 0 01.424-3.768 13.51 13.51 0 014.56-3.671 13.236 13.236 0 015.9-1.37c2.07 0 4.056.461 5.9 1.37a13.491 13.491 0 014.56 3.671 3.537 3.537 0 01.425 3.768 3.538 3.538 0 01-3.212 2.018m41.209-20.114H36.26a1.015 1.015 0 010-2.03h34.924a1.016 1.016 0 010 2.03M59.65 31.838H36.26a1.015 1.015 0 010-2.03h23.39a1.015 1.015 0 010 2.03m3.046 7.005H36.26a1.015 1.015 0 010-2.03h26.436a1.015 1.015 0 010 2.03m-.343 6.497H36.26a1.015 1.015 0 110-2.03h26.093a1.016 1.016 0 010 2.031"
        />
      </g>
    </svg>
  )
}

export default SvgMyDocuments
