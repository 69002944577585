import * as React from "react"

function SvgUserMenu(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        d="M8.536 6.464a4.99 4.99 0 00-1.9-1.191A2.892 2.892 0 007.89 2.89C7.89 1.297 6.594 0 5 0S2.11 1.297 2.11 2.89a2.89 2.89 0 001.254 2.383 4.99 4.99 0 00-1.9 1.191A4.971 4.971 0 000 10h.781A4.224 4.224 0 015 5.781 4.224 4.224 0 019.219 10H10a4.97 4.97 0 00-1.464-3.536zM5 5a2.112 2.112 0 01-2.11-2.11C2.89 1.728 3.838.782 5 .782c1.163 0 2.11.947 2.11 2.11A2.113 2.113 0 015 5z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgUserMenu
