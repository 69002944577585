import * as React from "react"

function SvgEssayAdmin(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M8.402 12.916c-.736 0-1.887-2.907-2.444-1.732-.231.49-.617 1.531-.99 2.365v.326c0 .547.444.99.99.99h7.917c.547 0 .99-.443.99-.99v-.461c-.56-1.259-1.284-3.345-1.98-4.146-1.423-1.64-3.123 3.648-4.483 3.648zM14.865 2H4.969A2.969 2.969 0 002 4.969v9.896a2.97 2.97 0 002.969 2.968h9.896a2.97 2.97 0 002.968-2.968V4.969A2.97 2.97 0 0014.865 2zM16 15a1 1 0 01-1 1H5a1 1 0 01-1-1V5a1 1 0 011-1h10a1 1 0 011 1v10zM6.453 7.937a1.484 1.484 0 10.002-2.967 1.484 1.484 0 00-.002 2.968z"
          fill="#5C5C5C"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgEssayAdmin
