import * as React from "react"

function SvgLandingPageInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="#FFFFFE">
          <path d="M16.998 6h.013c.549 0 .993.448.989 1.003A1.01 1.01 0 0116.989 8a1.001 1.001 0 01.009-2" />
          <path d="M19.261 16.512c.244-1.583.245-7.36.105-8.841-.152-1.584-1.066-2.625-2.545-2.87-.732-.118-2.495-.17-4.25-.17-1.692 0-3.376.048-4.118.133-1.482.18-2.478 1.15-2.715 2.711-.244 1.656-.24 7.845-.058 9.088.188 1.294.878 2.183 2.097 2.544.66.196 2.635.267 4.571.267 1.783 0 3.533-.06 4.191-.136 1.496-.174 2.486-1.15 2.722-2.726m1.635-8.993c.126 1.417.208 8.111-.186 9.68-.527 2.053-1.791 3.268-3.752 3.6-.733.125-2.712.201-4.688.201-2.024 0-4.044-.079-4.723-.266-1.928-.527-3.078-1.857-3.384-3.924-.228-1.52-.253-8.575.161-10.108.54-1.995 1.798-3.167 3.704-3.5C8.892 3.053 10.58 3 12.287 3c1.587 0 3.187.046 4.15.102 1.167.065 2.235.462 3.102 1.344.827.839 1.25 1.888 1.357 3.073" />
          <path d="M12.472 14.408a2.94 2.94 0 002.935-2.883 2.882 2.882 0 00-2.86-2.94h-.027a2.94 2.94 0 00-2.935 2.883 2.887 2.887 0 002.86 2.94h.026M12.538 7c2.48 0 4.48 2.016 4.462 4.504A4.544 4.544 0 0112.462 16 4.456 4.456 0 018 11.497 4.535 4.535 0 0112.538 7" />
        </g>
      </g>
    </svg>
  )
}

export default SvgLandingPageInstagram
