import * as React from "react"

function SvgAlertError(props) {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="46"
        cy="45.5688"
        rx="46"
        ry="45.5688"
        fill="#6C5DD3"
        fill-opacity="0.11"
      />
      <ellipse cx="46" cy="60.9437" rx="6" ry="5.94375" fill="#6C5DD3" />
      <path
        d="M46 31L46 46"
        stroke="#6C5DD3"
        stroke-width="12"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    /*  <svg
      width={96}
      height={96}
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#6E71A0" opacity={0.105} cx={48} cy={48} r={48} />
        <circle fill="#6E71A0" opacity={0.046} cx={48} cy={48} r={42.857} />
        <circle fill="#6E71A0" cx={48} cy={48} r={38.571} />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M73.238 59.976L51.043 22.294c-1.353-2.296-4.733-2.297-6.086 0L22.762 59.976c-1.352 2.296.336 5.167 3.043 5.167h44.39c2.704 0 4.397-2.869 3.043-5.167zM70 61.959H26a.485.485 0 01-.431-.73L47.569 24a.503.503 0 01.862 0L70.432 61.23a.485.485 0 01-.43.73z" />
          <path d="M49.104 57.236a1.286 1.286 0 10-1.351-2.187 1.286 1.286 0 001.351 2.187zM48.429 53.143c.71 0 1.285-.657 1.285-1.468V34.896c0-.81-.575-1.467-1.285-1.467s-1.286.657-1.286 1.467v16.779c0 .81.575 1.468 1.286 1.468z" />
        </g>
      </g>
    </svg> */
  )
}

export default SvgAlertError
