import * as React from "react"

function SvgSuccess(props) {
  return (
    <svg
      width="72"
      height="71"
      viewBox="0 0 72 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="35.836"
        cy="35.5"
        rx="35.836"
        ry="35.5"
        fill="#EA4C89"
        fill-opacity="0.11"
      />
      <path
        d="M31.6665 48C30.5832 48 29.4998 47.5667 28.6332 46.7L19.9665 38.0333C18.2332 36.3 18.2332 33.7 19.9665 31.9667C21.6998 30.2333 24.5165 30.2333 26.0332 31.9667L31.6665 37.6L45.9665 23.3C47.6998 21.5667 50.2998 21.5667 52.0332 23.3C53.7665 25.0333 53.7665 27.6333 52.0332 29.3667L34.6998 46.7C33.8332 47.5667 32.7498 48 31.6665 48Z"
        fill="#EA4C89"
      />
    </svg>
    /*  <svg
      xmlns="http://www.w3.org/2000/svg"
      width={96}
      height={96}
      viewBox="0 0 96 96"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h96v96H0z" />
        <g transform="translate(4 4)">
          <path
            fill="#FF78AC"
            fillRule="nonzero"
            d="M66.242 27.772a6.08 6.08 0 010 8.553l-24 24.195c-1.172 1.182-2.706 1.773-4.242 1.773s-3.07-.591-4.242-1.773l-12-12.097a6.08 6.08 0 010-8.553 5.963 5.963 0 018.484 0L38 47.69l19.758-19.918a5.963 5.963 0 018.484 0z"
          />
          <circle cx={44} cy={44} r={43.5} stroke="#FF78AC" opacity={0.2} />
          <circle
            cx={44}
            cy={44}
            r={35}
            stroke="#FF78AC"
            strokeWidth={2}
            opacity={0.4}
          />
        </g>
      </g>
    </svg> */
  )
}

export default SvgSuccess
