import * as React from "react"

function SvgIcDelete(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={22}
      height={23}
      viewBox="0 0 22 23"
      {...props}
    >
      <defs>
        <path
          id="ic-delete_svg__a"
          d="M14.143 12.019v3.055c0 .611-.42 1.019-1.048 1.019-.628 0-1.047-.408-1.047-1.019V12.02c0-.612.419-1.019 1.047-1.019.629 0 1.048.407 1.048 1.019zm-4.19 0v3.055c0 .611-.42 1.019-1.048 1.019-.629 0-1.048-.408-1.048-1.019V12.02c0-.612.42-1.019 1.048-1.019.628 0 1.047.407 1.047 1.019zM6.81 8.963c-.629 0-1.048.407-1.048 1.018v7.13c0 .611.419 1.019 1.048 1.019h8.38c.629 0 1.048-.408 1.048-1.019v-7.13c0-.61-.419-1.018-1.048-1.018H6.81zM5.762 6.926h10.476c1.152 0 2.095.917 2.095 2.037v9.167c0 1.12-.943 2.037-2.095 2.037H5.762c-1.152 0-2.095-.917-2.095-2.037V8.963c0-1.12.943-2.037 2.095-2.037zm4.19-3.565v-.51c0-.61.42-1.018 1.048-1.018.629 0 1.048.408 1.048 1.019v.51h5.238c.628 0 1.047.407 1.047 1.018 0 .61-.419 1.018-1.047 1.018H4.714c-.628 0-1.047-.407-1.047-1.018s.419-1.02 1.047-1.02h5.238z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="ic-delete_svg__b" fill="#fff">
          <use xlinkHref="#ic-delete_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#ic-delete_svg__a" />
        <g fill="#252525" mask="url(#ic-delete_svg__b)">
          <path d="M0 0h22v22H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgIcDelete
