import * as React from "react"

function SvgHeartFilled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="heart-filled_svg__a"
          d="M12 21a1.16 1.16 0 01-.773-.294c-.808-.715-1.587-1.386-2.274-1.979l-.004-.003c-2.015-1.737-3.755-3.237-4.965-4.714C2.63 12.358 2 10.79 2 9.08c0-1.662.564-3.196 1.587-4.318a5.35 5.35 0 014-1.762c1.155 0 2.212.37 3.143 1.097.47.368.895.818 1.27 1.342.375-.524.8-.974 1.27-1.342C14.202 3.37 15.26 3 16.414 3a5.35 5.35 0 014 1.762C21.438 5.884 22 7.418 22 9.08c0 1.711-.63 3.278-1.983 4.93-1.211 1.477-2.95 2.977-4.966 4.714-.688.593-1.468 1.266-2.278 1.982-.214.19-.488.294-.773.294z"
        />
      </defs>
      <g fill="currentColor" fillRule="evenodd">
        <mask id="heart-filled_svg__b" fill="#fff">
          <use xlinkHref="#heart-filled_svg__a" />
        </mask>
        <use fill="none" fillRule="nonzero" xlinkHref="#heart-filled_svg__a" />
        <g mask="url(#heart-filled_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgHeartFilled
