import * as React from "react"

function SvgBannerAdmin(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M2.99 2a.99.99 0 00-.99.99v13.854a.99.99 0 101.98 0V2.99A.99.99 0 002.99 2zm13.854 0H6.948a.99.99 0 00-.99.99v7.916c0 .547.443.99.99.99h9.896a.99.99 0 00.99-.99V2.99a.99.99 0 00-.99-.99zm-.99 7.422a.495.495 0 01-.495.495H8.432a.495.495 0 01-.495-.495V4.474a.495.495 0 01.495-.495h6.927c.274 0 .495.222.495.495v4.948z"
          fill="#5C5C5C"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgBannerAdmin
