import * as React from "react"

function SvgAnonimattaEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <defs>
        <path
          id="AnonimattaEdit_svg__a"
          d="M3.4 10.069l2.476 2.476c.111.11.278.11.39 0L12.44 6.34a.27.27 0 000-.39L9.993 3.503a.27.27 0 00-.39 0L3.4 9.707c-.111.111-.111.278 0 .362zm7.4-7.706a.268.268 0 000 .39L13.248 5.2a.268.268 0 00.39 0l.695-.695c.445-.418.445-1.085 0-1.53l-1.308-1.308a1.108 1.108 0 00-1.585 0l-.64.696zM1.34 14.186a.405.405 0 00.473.473l3.032-.723a.529.529 0 00.25-.14l.056-.055c.056-.056.084-.25-.027-.362L2.62 10.875c-.111-.11-.306-.083-.362-.027l-.055.055c-.084.084-.112.167-.14.25l-.723 3.033z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="AnonimattaEdit_svg__b" fill="#fff">
          <use xlinkHref="#AnonimattaEdit_svg__a" />
        </mask>
        <use
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#AnonimattaEdit_svg__a"
        />
        <g fill="#EA4C89" mask="url(#AnonimattaEdit_svg__b)">
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgAnonimattaEdit
