import * as React from "react"

function SvgSend(props) {
  return (
    <svg
      width={15}
      height={16}
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="send_svg__a" d="M0 0h14.62v15.585H0z" />
      </defs>
      <g transform="translate(-9 -8)" fill="none" fillRule="evenodd">
        <rect width={32} height={32} rx={2} />
        <g transform="translate(9.143 8)">
          <mask id="send_svg__b" fill="#fff">
            <use xlinkHref="#send_svg__a" />
          </mask>
          <path
            d="M13.398 8.064a.719.719 0 01-.329.359L1.755 14.41c-.254.134-.56.018-.682-.26a.606.606 0 01-.015-.45l1.957-5.35h4.92c.282 0 .51-.25.51-.559 0-.308-.228-.558-.51-.558h-4.92l-1.957-5.35a.58.58 0 01.126-.615.463.463 0 01.57-.094l11.307 5.982c.16.085.29.232.352.415a.685.685 0 01-.015.492m1.069-1.002a1.61 1.61 0 00-.711-.778L2.199.168C1.44-.235.522.113.153.945A1.819 1.819 0 00.11 2.298l2.01 5.494-2.01 5.494c-.313.862.072 1.838.86 2.18.178.078.368.118.56.12.232 0 .46-.058.67-.168L13.755 9.3c.761-.404 1.08-1.405.71-2.238"
            fill="#52546B"
            mask="url(#send_svg__b)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgSend
