import * as React from "react"

function SvgLandingPageTwitter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21 6.795a5.817 5.817 0 01-.418.58c-.39.497-.84.936-1.346 1.311l-.063.12c.016.56-.009 1.12-.081 1.68a11.507 11.507 0 01-.97 3.373 11.188 11.188 0 01-2.013 2.983c-1.417 1.495-3.112 2.459-5.085 2.905A11.076 11.076 0 018.636 20c-1.995 0-3.844-.547-5.547-1.635L3 18.309c.292.032.58.047.866.047.973 0 1.91-.187 2.824-.564.63-.27 1.21-.61 1.758-1.056-1.908-.064-3.096-1.481-3.405-2.63a3.4 3.4 0 00.684.069c.299 0 .596-.038.895-.11l.055-.03c-1.039-.246-1.834-.813-2.377-1.735-.37-.61-.536-1.282-.531-2.012.522.283 1.058.44 1.637.453-.776-.574-1.292-1.331-1.499-2.295-.206-.964-.062-1.878.42-2.743C6.3 8.12 8.812 9.445 11.89 9.665c-.019-.156-.039-.284-.058-.419a3.756 3.756 0 01.562-2.473c.593-.949 1.431-1.537 2.507-1.713.229-.04.453-.059.67-.059.964 0 1.816.38 2.547 1.114.046.043.084.065.135.065l.057-.008a7.158 7.158 0 002.15-.843l.048-.029c-.268.878-.804 1.559-1.565 2.062A6.988 6.988 0 0021 6.795"
          fill="#FFFFFE"
        />
      </g>
    </svg>
  )
}

export default SvgLandingPageTwitter
