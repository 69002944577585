import * as React from "react"

function SvgCommentWhite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          id="comment-white_svg__a"
          d="M21.998 11.595a9.756 9.756 0 00-3.023-6.865A9.755 9.755 0 0012 2.002 9.752 9.752 0 005.024 4.73 9.751 9.751 0 002 11.595v.017a9.437 9.437 0 003.138 6.978v2.417a.99.99 0 00.979.993.97.97 0 00.518-.149l1.943-1.214a10.25 10.25 0 003.401.578h.015c.064.002.128.002.192.002a9.757 9.757 0 006.79-2.73 9.758 9.758 0 003.022-6.865v-.027zm-3.835 6.045a8.594 8.594 0 01-6.152 2.403h-.032a9.084 9.084 0 01-3.142-.559.852.852 0 00-.75.077l-1.777 1.11v-2.224a.857.857 0 00-.296-.648 8.261 8.261 0 01-2.84-6.185 8.592 8.592 0 012.66-6.037 8.593 8.593 0 016.153-2.402h.024a8.581 8.581 0 016.152 2.402 8.593 8.593 0 012.662 6.032 8.589 8.589 0 01-2.662 6.031z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="comment-white_svg__b" fill="#fff">
          <use xlinkHref="#comment-white_svg__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#comment-white_svg__a" />
        <g fill="#FFF" mask="url(#comment-white_svg__b)">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgCommentWhite
