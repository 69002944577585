import * as React from "react"

function SvgPostAdminWhite(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M4.229 13.376A2.234 2.234 0 002 15.605a2.234 2.234 0 002.229 2.228 2.234 2.234 0 002.229-2.228 2.234 2.234 0 00-2.23-2.229zm-.603-5.53C2.766 7.702 2 8.408 2 9.276c0 .727.542 1.31 1.258 1.432 2.985.521 5.336 2.883 5.868 5.868.123.716.706 1.257 1.431 1.257.87 0 1.575-.766 1.442-1.625-.7-4.3-4.073-7.669-8.373-8.362zm-.031-5.838C2.746 1.916 2 2.601 2 3.45c0 .746.562 1.36 1.298 1.43 6.145.614 11.032 5.5 11.645 11.645a1.435 1.435 0 001.432 1.308c.859 0 1.533-.746 1.452-1.594C17.08 8.735 11.11 2.755 3.595 2.009z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgPostAdminWhite
