import * as React from "react"

function SvgTurnOff(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="#EA4C89" fillRule="nonzero">
        <path d="M11.2 1.938c-.369-.147-.797.007-.958.344-.16.338.007.731.375.879 2.367.948 3.928 3.094 3.928 5.503 0 3.315-2.93 6.002-6.545 6.002-3.615 0-6.545-2.687-6.545-6.002 0-2.409 1.56-4.555 3.927-5.503.368-.148.535-.541.374-.879-.16-.337-.59-.491-.957-.344C1.909 3.098 0 5.721 0 8.664 0 12.716 3.582 16 8 16s8-3.285 8-7.336c0-2.944-1.909-5.567-4.8-6.726z" />
        <path d="M8 7.53c.552 0 1-.307 1-.685V.685C9 .306 8.552 0 8 0S7 .306 7 .684v6.16c0 .379.448.685 1 .685z" />
      </g>
    </svg>
  )
}

export default SvgTurnOff
