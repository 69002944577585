import * as React from "react"

function SvgPhotoWait(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={42}
      height={42}
      viewBox="0 0 42 42"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={21} cy={21} r={21} fill="#6E71A0" />
        <path
          fill="#FFF"
          d="M27.75 30h-13.5A2.25 2.25 0 0112 27.75v-13.5A2.25 2.25 0 0114.25 12h13.5A2.25 2.25 0 0130 14.25v13.5A2.25 2.25 0 0127.75 30zm.25-4.098L24.693 23 20 28h8v-2.098zM14 14v14h2.304l7.158-7.756a1.271 1.271 0 011.782-.1L28 22.605V14H14zm4.5 7a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
        />
      </g>
    </svg>
  )
}

export default SvgPhotoWait
