import * as React from "react"

function SvgBackArrow(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.367 3.19l.585.602c.135.14.21.324.21.52a.74.74 0 01-.21.52l-5.94 6.126h17.026c.257 0 .467.209.467.465v1.21c0 .257-.21.466-.467.466H5.008l5.944 6.13c.135.139.21.324.21.52a.74.74 0 01-.21.52l-.585.602a.718.718 0 01-1.037 0l-7.602-7.84a.533.533 0 01-.024-.027.464.464 0 01-.184-.37v-1.211a.46.46 0 01.192-.375l.017-.02 7.602-7.839a.717.717 0 011.036 0z"
        fill="#252525"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgBackArrow
