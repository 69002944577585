import * as React from "react"

function SvgMySelfie(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h80v80H0z" />
        <path
          fill="#D28867"
          d="M17.26 73.335l-2.083-12.733s8.103-.232 9.955-7.871c1.852-7.64.232-8.798.232-8.798s4.167-14.353 2.315-16.437c-1.852-2.083-7.408-.231-7.408-.231s-1.853 6.25-1.853 5.556c0-.695-.463-14.354-.463-14.354s-8.334 7.177-8.334 7.872c0 .694-7.408 22.919-7.408 22.919l-.695 23.845c4.833 2.442 10.097 2.427 15.743.232m14.636-58.544l-.67.898h-7.252c-1.224 0-2.377.31-3.387.852l7.646-7.973a2.58 2.58 0 013.644-.076 4.369 4.369 0 01.129 6.169c-.039.04-.076.085-.11.13"
        />
        <path
          fill="#54768A"
          d="M77.047 50.417c-.512 1.861-2.202 3.24-4.224 3.24H26.136c.233-.605.424-1.224.582-1.852h46.105a7.13 7.13 0 004.224-1.388"
        />
        <path
          fill="#EA4C89"
          d="M49.469 23.075c3.696 0 5.04 2.253 5.425 4.655-4.158-.16-5.244-1.61-5.985-3.093a1.39 1.39 0 00-2.62.437c-.042.294-.434 2.536-2.335 3.404.221-2.659 1.436-5.403 5.515-5.403"
        />
        <path
          fill="#E0EFFF"
          d="M35.73 43.51c.166-.214 3.513-4.5 7.564-6.15-1.362-2.107-2.161-4.943-2.161-7.885 0-5.747 3.116-9.178 8.336-9.178 5.22 0 8.336 3.43 8.336 9.178 0 2.974-.814 5.834-2.2 7.947 3.96 1.681 7.23 5.88 7.393 6.09a1.389 1.389 0 11-2.202 1.695c-1.108-1.44-4.67-5.263-7.794-5.662a1.392 1.392 0 01-.758-2.406c1.503-1.369 2.529-3.902 2.738-6.638-3.528-.112-5.596-1.052-6.902-2.295-.775 1.36-2.062 2.66-4.023 3.165.34 2.378 1.286 4.522 2.616 5.746a1.388 1.388 0 01-.792 2.403c-3.172.342-6.816 4.222-7.952 5.69a1.39 1.39 0 01-2.198-1.7zm36.862-13.467a1.39 1.39 0 112.778 0v7.408a1.39 1.39 0 01-2.778 0v-7.408zm-53.016-7.177v3.139a6.21 6.21 0 015.97-1.67 6.205 6.205 0 013.835 2.855 6.208 6.208 0 01.693 4.732L27.04 43.953l.162 3.053c.036.675.015 1.349-.035 2.02h45.656a4.403 4.403 0 004.399-4.398V22.866a4.404 4.404 0 00-4.399-4.399H23.975a4.404 4.404 0 00-4.399 4.399z"
        />
        <path
          fill="#000"
          d="M19.576 22.866a4.404 4.404 0 014.399-4.399h48.848a4.404 4.404 0 014.399 4.399v21.762a4.404 4.404 0 01-4.399 4.399H27.167c.05-.672.071-1.346.035-2.02l-.162-3.054 3.034-12.031a6.204 6.204 0 00-.693-4.732 6.208 6.208 0 00-3.835-2.855 6.205 6.205 0 00-5.97 1.67v-3.14zm7.142 28.939h46.105a7.13 7.13 0 004.224-1.388c-.512 1.861-2.202 3.24-4.224 3.24H26.136c.233-.605.424-1.224.582-1.852zm5.289-37.145c-.039.04-.076.085-.11.13l-.67.898h-7.252c-1.224 0-2.377.31-3.387.852l7.646-7.973a2.58 2.58 0 013.644-.076 4.369 4.369 0 01.129 6.169zM2.336 44.045a45.263 45.263 0 00-2.322 15.51L.361 73.37a1.39 1.39 0 001.388 1.355h.035a1.39 1.39 0 001.355-1.423l-.347-13.816a42.487 42.487 0 012.179-14.561L9.868 30.25a16.586 16.586 0 013.849-6.543l3.81-3.974a7.132 7.132 0 00-.73 3.133v10.603l-2.089 8.285c-.188.744.263 1.5 1.006 1.686.747.19 1.5-.263 1.687-1.006l2.046-8.11c.066-.144.105-.303.118-.47l1.087-4.309a3.45 3.45 0 011.585-2.131 3.457 3.457 0 012.628-.385 3.473 3.473 0 012.516 4.213l-3.085 12.235a1.372 1.372 0 00-.041.413l.173 3.264A13.412 13.412 0 0122.1 55.42c-.596.867-1.39 1.632-2.357 2.275l-.049.021c-3.087 1.364-4.496 1.992-7.296 1.516a1.388 1.388 0 10-.46 2.74c.796.134 1.514.196 2.183.196.084 0 .163-.008.245-.01l1.283 11.333a1.388 1.388 0 102.76-.312l-1.297-11.46c1.15-.34 2.31-.845 3.705-1.462l.153-.067c.068-.03.134-.065.196-.106 1.316-.854 2.4-1.894 3.223-3.09.125-.181.234-.373.351-.56h48.083c3.958 0 7.177-3.219 7.177-7.176V22.866c0-3.957-3.22-7.177-7.177-7.177H34.731c1.953-2.83 1.63-6.747-.93-9.202a5.363 5.363 0 00-7.572.159L11.711 21.784A19.344 19.344 0 007.224 29.4L2.336 44.045z"
        />
        <path
          fill="#000"
          d="M73.98 28.654c.768 0 1.39.622 1.39 1.389v7.408a1.39 1.39 0 01-2.778 0v-7.408c0-.767.621-1.39 1.389-1.39m-30.027-.175c.221-2.659 1.436-5.403 5.515-5.403 3.696 0 5.04 2.253 5.425 4.655-4.158-.16-5.244-1.61-5.985-3.093a1.39 1.39 0 00-2.62.437c-.042.294-.434 2.536-2.335 3.404M35.73 43.51a1.39 1.39 0 002.199 1.699c1.136-1.467 4.78-5.347 7.951-5.689a1.388 1.388 0 00.793-2.403c-1.33-1.224-2.277-3.368-2.616-5.746 1.961-.506 3.248-1.804 4.023-3.165 1.306 1.243 3.374 2.183 6.902 2.295-.209 2.736-1.235 5.269-2.739 6.638a1.39 1.39 0 00.759 2.405c3.124.4 6.686 4.224 7.794 5.663a1.389 1.389 0 002.202-1.695c-.162-.21-3.432-4.409-7.392-6.09 1.385-2.113 2.199-4.973 2.199-7.947 0-5.747-3.116-9.178-8.336-9.178-5.22 0-8.336 3.43-8.336 9.178 0 2.942.799 5.778 2.16 7.885-4.05 1.65-7.397 5.936-7.563 6.15"
        />
      </g>
    </svg>
  )
}

export default SvgMySelfie
