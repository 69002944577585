import * as React from "react"

function SvgCloseModal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      {...props}
    >
      <g fill="#252525" fillRule="evenodd">
        <path d="M13.17 1.195l1.275 1.237L1.69 14.806.415 13.568z" />
        <path d="M1.69 1.194L.417 2.431 13.17 14.805l1.275-1.237z" />
      </g>
    </svg>
  )
}

export default SvgCloseModal
