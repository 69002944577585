import * as React from "react"

function SvgTooltipInfo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={6} cy={6} r={6} fill="#D8D8D8" />
        <circle cx={6} cy={3} r={1} fill="#585858" />
        <rect width={2} height={5} x={5} y={5} fill="#585858" rx={1} />
      </g>
    </svg>
  )
}

export default SvgTooltipInfo
