import * as React from "react"

function SvgOrder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#252525"
          d="M12.833 0H5.167C4.154 0 3.333.82 3.333 1.833v9c0 1.013.821 1.834 1.834 1.834h7.666a1.834 1.834 0 001.834-1.834v-9A1.834 1.834 0 0012.833 0z"
        />
        <path
          d="M11.5 9h-5a.5.5 0 010-1h5a.5.5 0 010 1zm0-2h-5a.5.5 0 010-1h5a.5.5 0 010 1zm0-2h-5a.5.5 0 010-1h5a.5.5 0 010 1z"
          fill="#FFF"
        />
        <path
          fill="#BFBFBF"
          d="M5.167 13.667a2.837 2.837 0 01-2.834-2.834v-9c0-.057.014-.11.017-.166h-.517A1.835 1.835 0 000 3.5v10.667C0 15.178.822 16 1.833 16h8a1.835 1.835 0 001.834-1.833v-.5h-6.5z"
        />
      </g>
    </svg>
  )
}

export default SvgOrder
