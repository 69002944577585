import * as React from "react"

function SvgCreatorMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={13}
      viewBox="0 0 18 13"
      {...props}
    >
      <path
        d="M15.742 13H2.258C1.014 12.999.006 12.17 0 11.143V1.857C0 1.363.238.89.662.542 1.085.193 1.66-.002 2.258 0h13.484C16.986.001 17.994.83 18 1.857v9.286c0 .494-.238.967-.662 1.315-.423.349-.998.544-1.596.542zM16 11V3l-5.77 4.774c-.327.265-.773.413-1.238.413-.464 0-.91-.148-1.238-.413L2 3.011V11h14zm-2-9H4l5 4 5-4z"
        fill="#6E71A0"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgCreatorMail
