import * as React from "react"

function SvgFacebookInactive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={9}
      height={17}
      viewBox="0 0 9 17"
      {...props}
    >
      <path
        d="M7.357 2.823H9V.12A22.36 22.36 0 006.606 0C4.237 0 2.614 1.407 2.614 3.994v2.381H0v3.022h2.614V17H5.82V9.397h2.508l.398-3.021H5.82V4.294c0-.873.25-1.471 1.538-1.471z"
        fill="#84858F"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgFacebookInactive
