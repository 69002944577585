import * as React from "react"

function SvgClipboard(props) {
  return (
    <svg
      width={17}
      height={20}
      viewBox="0 0 17 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.75 0H5.464C4.18 0 3.036 1.213 3.036 2.5l-.702.017C1.05 2.517 0 3.713 0 5v12.5C0 18.787 1.144 20 2.429 20h9.107c1.284 0 2.428-1.213 2.428-2.5h.607C15.856 17.5 17 16.287 17 15V5.015L12.75 0zm-1.214 18.75H2.429c-.638 0-1.215-.613-1.215-1.25V5c0-.637.52-1.23 1.157-1.23l.665-.02V15c0 1.287 1.144 2.5 2.428 2.5h7.286c0 .637-.577 1.25-1.214 1.25zm4.25-3.75c0 .637-.577 1.25-1.215 1.25H5.464c-.637 0-1.214-.613-1.214-1.25V2.5c0-.637.577-1.25 1.214-1.25h6.072c-.01 1.44 0 2.516 0 2.516 0 1.298 1.135 2.484 2.428 2.484h1.822V15zM13.964 5c-.646 0-1.214-1.21-1.214-1.858V1.269L15.786 5h-1.822zM12.75 8.761H7.286a.616.616 0 00-.607.625c0 .345.272.624.607.624h5.464c.335 0 .607-.28.607-.624a.616.616 0 00-.607-.625zm0 3.122H7.286a.616.616 0 00-.607.624c0 .346.272.625.607.625h5.464c.335 0 .607-.28.607-.625a.616.616 0 00-.607-.624z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.6}
      />
    </svg>
  )
}

export default SvgClipboard
