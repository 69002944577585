import * as React from "react"

function SvgLandingPageFooterLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={156}
      height={46}
      viewBox="0 0 156 46"
      {...props}
    >
      <defs>
        <path
          id="landing-page-footer-logo_svg__a"
          d="M.057 0h14.348v46H.057z"
        />
        <path
          id="landing-page-footer-logo_svg__c"
          d="M.037.035h15.136v45.181H.037z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FEFEFE"
          d="M110.569 5.758h4.406v39.854h6.23V5.758h4.405V.388h-15.041zm30.396-5.37h-15.041v5.37h4.405v39.854h6.231V5.758h4.405zM86.278 20.703h-.126L84.264.388h-7.993v45.224h5.224V20.51h.125l2.392 25.102h4.468l2.518-25.102h.125v25.102h5.161V.388h-7.992zM68.531 45.612h6.231V.389h-6.231zM27.607.819v24.303h-.062L23.754.819h-6.726V46h5.075V20.921h.122L26.444 46h6.666V.819zm33.751 23.895h-.063L57.393.389h-6.922v45.223h5.223V20.509h.126l4.342 25.103h6.86V.389h-5.664zM3.586.819L0 45.999h5.167l.79-11.375H8.51L9.36 46h5.776L11.306.819h-7.72zm3.982 17.517L8.48 29.84H6.656l.912-11.505z"
        />
        <g transform="translate(34.555)">
          <mask id="landing-page-footer-logo_svg__b" fill="#fff">
            <use xlinkHref="#landing-page-footer-logo_svg__a" />
          </mask>
          <path
            fill="#FEFEFE"
            d="M8.224 39.634a.994.994 0 11-1.987 0V6.366a.994.994 0 111.987 0v33.268zM12.36 1.618a5.672 5.672 0 00-2.108-1.23A7.736 7.736 0 007.861 0H6.6c-.837 0-1.644.13-2.42.388-.777.259-1.47.67-2.077 1.23-.609.56-1.101 1.283-1.48 2.167-.376.885-.565 1.93-.565 3.138v32.155c0 1.207.189 2.254.566 3.137.378.885.87 1.607 1.48 2.168.607.56 1.299.97 2.076 1.229A7.6 7.6 0 006.603 46H7.86c.796 0 1.593-.13 2.391-.388a5.637 5.637 0 002.108-1.23c.608-.56 1.101-1.282 1.479-2.167.378-.883.566-1.93.566-3.137V6.923c0-1.207-.188-2.253-.566-3.138-.378-.884-.87-1.606-1.479-2.167z"
            mask="url(#landing-page-footer-logo_svg__b)"
          />
        </g>
        <path
          fill="#FEFEFE"
          d="M103.859 29.432l.912-11.506.912 11.506h-1.824zM100.789.409L97.203 45.59h5.167l.79-11.376h2.553l.85 11.376h5.776L108.509.41h-7.72z"
        />
        <g transform="translate(139.711 .374)">
          <mask id="landing-page-footer-logo_svg__d" fill="#fff">
            <use xlinkHref="#landing-page-footer-logo_svg__c" />
          </mask>
          <path
            fill="#FEFEFE"
            d="M6.693 29.058l.912-11.506.912 11.506H6.693zM11.343.035h-7.72L.037 45.216h5.167l.79-11.376h2.553l.85 11.376h5.776L11.343.036z"
            mask="url(#landing-page-footer-logo_svg__d)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLandingPageFooterLogo
