import * as React from "react"

function SvgLandingPageFacebook(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M14.5 2c.509 0 1.02.029 1.528.053.33.014.661.064.972.1l-.023 3.22h-.244c-.336 0-.672-.005-1.007-.005-.252 0-.504.002-.757.012-.811.028-1.408.348-1.45 1.414-.025.768-.044 2.463-.045 2.553l1.651.002c.403 0 .798 0 1.073-.002h.655c-.164 1.236-.315 2.424-.479 3.618h-2.899L13.41 22H9.848l.065-9.05H7l.026-3.597h2.927l.002-.27c.005-.725-.003-1.45.022-2.175.01-.455.033-.924.112-1.38.196-1.1.683-2.025 1.56-2.686.768-.576 1.641-.817 2.568-.84L14.5 2"
          fill="#FFFFFE"
        />
      </g>
    </svg>
  )
}

export default SvgLandingPageFacebook
