import * as React from "react"

function SvgFavoriteHeart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="#EA4C89"
          d="M18.708.96H4.332C1.939.96 0 2.86 0 5.202v9.232c0 2.343 1.94 4.242 4.332 4.242h2.063c.098 0 .178.078.178.174v4.554c0 .539.672.8 1.05.408l4.88-5.081a.178.178 0 01.129-.055h6.076c2.393 0 4.332-1.9 4.332-4.242V5.202C23.04 2.859 21.1.96 18.708.96z"
        />
        <path
          fill="#BA3568"
          d="M9.6 16.922c0-.104-.08-.188-.177-.188H7.367c-2.383 0-4.316-2.049-4.316-4.577V2.194c0-.427.056-.841.16-1.234C1.865 1.709.96 3.12.96 4.737v9.46c0 2.401 1.996 4.347 4.458 4.347h2.123c.101 0 .183.08.183.179v4.667c0 .551.693.819 1.08.417l.796-.825v-6.06z"
        />
        <path
          fill="#EC5569"
          d="M17.405 5.632a2.855 2.855 0 00-4.03 0l-.629.626a.383.383 0 01-.542 0l-.62-.616a2.855 2.855 0 00-4.03 0 2.832 2.832 0 000 4.014l4.65 4.632c.15.15.393.15.543 0l4.658-4.642a2.83 2.83 0 000-4.014z"
        />
        <path
          fill="#FFF5F5"
          d="M17.405 5.632a2.855 2.855 0 00-4.03 0l-.629.626a.383.383 0 01-.542 0l-.62-.616a2.855 2.855 0 00-4.03 0 2.832 2.832 0 000 4.014l4.65 4.632c.15.15.393.15.543 0l4.658-4.642a2.83 2.83 0 000-4.014z"
        />
        <path
          fill="#DCE6EB"
          d="M8.164 9.651a2.773 2.773 0 010-4.02 2.964 2.964 0 011.312-.732 3.016 3.016 0 00-2.853.733 2.773 2.773 0 000 4.02l4.806 4.636c.155.15.406.15.561 0l.49-.473-4.316-4.164z"
        />
        <path
          fill="#000"
          d="M19.263 0H4.737a4.753 4.753 0 00-2.816.91.348.348 0 00-.076.493c.119.156.344.19.504.073a4.039 4.039 0 012.388-.77h14.526c2.215 0 4.017 1.765 4.017 3.935v9.335c0 2.17-1.802 3.936-4.017 3.936h-6.14a.547.547 0 00-.393.166L7.8 23.215a.25.25 0 01-.279.061.24.24 0 01-.16-.23V18.44a.536.536 0 00-.54-.53H4.738c-2.215 0-4.017-1.765-4.017-3.935V4.641c0-.798.243-1.567.702-2.222a.348.348 0 00-.094-.49.364.364 0 00-.5.09A4.547 4.547 0 000 4.642v9.335c0 2.56 2.125 4.641 4.737 4.641h1.904v4.428c0 .394.241.742.615.887a.98.98 0 001.068-.233l4.877-5.082h6.062c2.612 0 4.737-2.082 4.737-4.64V4.64C24 2.082 21.875 0 19.263 0z"
        />
        <path
          fill="#000"
          d="M15.665 10.404a.313.313 0 000 .473c.143.13.376.13.52 0l1.098-.998c1.276-1.16 1.276-3.048 0-4.209-1.277-1.16-3.354-1.16-4.63 0l-.641.583c-.002.001-.007.006-.017.006-.01 0-.015-.004-.017-.006l-.63-.573c-1.277-1.16-3.354-1.16-4.63 0-1.277 1.16-1.277 3.048 0 4.208l4.74 4.31c.144.13.335.202.537.202a.795.795 0 00.537-.202l2.606-2.369a.314.314 0 000-.473.395.395 0 00-.52 0l-2.606 2.37c-.002 0-.007.005-.017.005-.01 0-.015-.004-.016-.006l-4.742-4.31c-.99-.9-.99-2.363 0-3.263.99-.9 2.6-.9 3.59 0l.631.574c.143.13.334.202.537.202s.393-.072.537-.202l.64-.583c.99-.9 2.6-.9 3.59 0 .99.9.99 2.363 0 3.263l-1.097.998z"
        />
      </g>
    </svg>
  )
}

export default SvgFavoriteHeart
