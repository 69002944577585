import * as React from "react"

function SvgEyeBlocked(props) {
  return (
    <svg
      width={16}
      height={14}
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.998 2.947c2.007 0 3.636 1.65 3.636 3.685 0 .475-.095.928-.258 1.344l2.126 2.156A8.747 8.747 0 0016 6.632c-1.262-3.235-4.363-5.527-8.002-5.527a8.403 8.403 0 00-2.897.516L6.67 3.21c.411-.162.858-.262 1.327-.262zM.728.94l1.657 1.68.33.336A8.718 8.718 0 000 6.632c1.258 3.234 4.363 5.526 7.998 5.526a8.427 8.427 0 003.189-.623l.309.313L13.616 14l.926-.936L1.654 0 .727.94zm4.02 4.072l1.123 1.138a2.365 2.365 0 00-.054.483c0 1.22.978 2.21 2.181 2.21.164 0 .32-.022.473-.055l1.123 1.138a3.539 3.539 0 01-1.596.39c-2.007 0-3.635-1.65-3.635-3.683 0-.583.145-1.128.385-1.621zm3.13-.579l2.29 2.321.012-.121c0-1.22-.978-2.21-2.182-2.21l-.12.01z"
        fill="#52546B"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgEyeBlocked
