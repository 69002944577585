import * as React from "react"

function SvgFeedContent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <path id="feed-content_svg__a" d="M0 0h21.76v1.28H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#DFEDF8"
          d="M.798 23.688l.075-1.955-.075-3.33zM1.28 3.207c0-.706.573-1.28 1.28-1.28h1.015V16.53l6.568-4.427 7.11 4.95 6.574-3.24 6.976 4.257V1.436H.797v16.966l.483-.325V3.208zm29.522 20.48v-.053l-.056.053z"
        />
        <path
          fill="#547689"
          d="M31.202 18.313l-.4-.244v5.565l.4-.371zM2.56 23.687H.797l1.426.746h27.719l.804-.746H29.44z"
        />
        <path
          fill="#547689"
          d="M1.28 22.407v-4.33l-.483.326.075 3.33-.075 1.954H2.56a1.28 1.28 0 01-1.28-1.28m22.546-8.594l-6.573 3.24-7.11-4.95-6.568 4.428v3.761c0 .708.573 1.28 1.28 1.28H30.72v.836a1.28 1.28 0 01-1.28 1.28h1.306l.056-.053v-5.566l-6.976-4.256z"
        />
        <path
          fill="#A7B1BB"
          d="M2.56 1.927a1.28 1.28 0 00-1.28 1.28v14.87l2.295-1.547V1.927H2.56z"
        />
        <path
          fill="#3B5767"
          d="M1.28 22.407c0 .707.573 1.28 1.28 1.28h26.88a1.28 1.28 0 001.28-1.28v-.835H4.855a1.28 1.28 0 01-1.28-1.28V16.53L1.28 18.078v4.33z"
        />
        <path
          fill="#010202"
          d="M21.76 6.336c1.094 0 1.984.89 1.984 1.984 0 1.095-.89 1.984-1.984 1.984a1.986 1.986 0 01-1.984-1.984c0-1.094.89-1.984 1.984-1.984m0 5.184c1.764 0 3.2-1.436 3.2-3.2 0-1.764-1.436-3.2-3.2-3.2a3.204 3.204 0 00-3.2 3.2c0 1.764 1.436 3.2 3.2 3.2"
        />
        <g transform="translate(5.12 29.296)">
          <mask id="feed-content_svg__b" fill="#fff">
            <use xlinkHref="#feed-content_svg__a" />
          </mask>
          <path
            fill="#010202"
            d="M.64 0h20.48a.64.64 0 110 1.28H.64A.64.64 0 11.64 0"
            mask="url(#feed-content_svg__b)"
          />
        </g>
        <path
          fill="#010202"
          d="M22.75 27.376a.64.64 0 00-.64-.64H3.2a.64.64 0 100 1.28h18.91a.64.64 0 00.64-.64m6.05-.64h-3.2a.64.64 0 100 1.28h3.2a.64.64 0 100-1.28m1.92-9.33l-5.674-3.536a2.555 2.555 0 00-2.714-.078l-4.612 2.519a.64.64 0 01-.706-.062l-5.205-4.151a2.573 2.573 0 00-1.567-.578 2.57 2.57 0 00-1.578.503l-1.572 1.126a.64.64 0 00.746 1.041l1.582-1.135c.231-.172.497-.27.796-.255.288.007.56.106.789.294l5.21 4.156a1.917 1.917 0 002.118.184l4.635-2.532c.207-.125.428-.179.689-.182.243.006.477.08.696.225l6.367 3.97V22.4a1.28 1.28 0 01-1.28 1.28H2.56a1.28 1.28 0 01-1.28-1.28v-3.512l4.276-3.064a.64.64 0 00-.745-1.04l-3.531 2.53V3.2c0-.707.573-1.28 1.28-1.28h26.88c.707 0 1.28.573 1.28 1.28v14.207zM29.44.641H2.56A2.562 2.562 0 000 3.2v19.2a2.562 2.562 0 002.56 2.56h26.88A2.562 2.562 0 0032 22.4V3.2A2.563 2.563 0 0029.44.64z"
        />
        <path
          fill="#E84C89"
          d="M21.76 6.336c1.094 0 1.984.89 1.984 1.984 0 1.095-.89 1.984-1.984 1.984a1.986 1.986 0 01-1.984-1.984c0-1.094.89-1.984 1.984-1.984"
        />
      </g>
    </svg>
  )
}

export default SvgFeedContent
