import * as React from "react"

function SvgFullScreen(props) {
  return (
    <svg
      width={22}
      height={17}
      viewBox="0 0 22 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#52546B" fillRule="nonzero">
        <path d="M0 0v16.336h21.6V0H0zm20.334 15.14H1.266V1.196h19.068v13.946z" />
        <path d="M7.61 12.352H5.114L9.033 8.65l-.895-.845-3.92 3.701V9.15H2.954v4.398H7.61zM13.462 8.53l3.92-3.7v2.357h1.265V2.789H13.99v1.195h2.496l-3.919 3.702z" />
      </g>
    </svg>
  )
}

export default SvgFullScreen
