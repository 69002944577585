import * as React from "react"

function SvgMobileEssayActive(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 22 22"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M5.945 16.86H5c.001-2.525 1.634-4.772 4.07-5.6a3.384 3.384 0 01-1.363-3.836c.453-1.433 1.806-2.41 3.339-2.41 1.532 0 2.885.977 3.338 2.41a3.385 3.385 0 01-1.362 3.836c2.435.829 4.067 3.076 4.068 5.6h-.944c0-2.76-2.285-4.996-5.103-4.996-2.817 0-5.102 2.237-5.102 4.996h.004zm5.103-10.917c-1.41 0-2.552 1.118-2.552 2.497 0 1.38 1.143 2.498 2.552 2.498s2.55-1.118 2.55-2.498c0-1.378-1.141-2.496-2.549-2.497z"
          fill="#EA4C89"
          fillRule="nonzero"
        />
        <g transform="translate(1 1)">
          <rect width={20} height={20} rx={3} />
          <rect
            width={19}
            height={19}
            x={0.5}
            y={0.5}
            stroke="#EA4C89"
            rx={2.5}
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgMobileEssayActive
