import * as React from "react"

function SvgAnonimattaWhatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M11.96 2.035A6.935 6.935 0 007.03 0C3.186 0 .06 3.112.057 6.937c0 1.223.32 2.416.93 3.468L0 14l3.695-.965a6.999 6.999 0 003.331.845h.003c3.842 0 6.97-3.113 6.971-6.938a6.881 6.881 0 00-2.04-4.907zM7.03 12.708h-.003c-1.04 0-2.06-.278-2.949-.804l-.211-.125-2.193.573.585-2.128-.138-.218a5.738 5.738 0 01-.886-3.069c.002-3.179 2.6-5.765 5.797-5.765 1.547 0 3.002.6 4.096 1.69a5.718 5.718 0 011.695 4.08c-.001 3.18-2.6 5.766-5.794 5.766zm3.177-4.318c-.174-.087-1.03-.506-1.19-.564-.16-.058-.276-.087-.392.086-.116.174-.45.564-.551.68-.102.116-.203.13-.378.043-.174-.086-.735-.27-1.4-.86a5.268 5.268 0 01-.97-1.2c-.1-.174 0-.26.077-.354.189-.233.378-.478.436-.593.058-.116.029-.217-.015-.304-.043-.087-.392-.94-.537-1.287-.141-.338-.285-.292-.392-.298a7.053 7.053 0 00-.334-.006.643.643 0 00-.464.217c-.16.174-.61.593-.61 1.446s.624 1.678.711 1.793c.088.116 1.229 1.867 2.976 2.618.415.178.74.285.993.365.417.132.797.113 1.097.069.335-.05 1.03-.42 1.176-.825.145-.405.145-.752.101-.824-.043-.072-.16-.116-.334-.202z"
        fill="#000"
        fillRule="nonzero"
        opacity={0.6}
      />
    </svg>
  )
}

export default SvgAnonimattaWhatsapp
